<div
   [ngClass]="{ 'search-filters': true, 'isDrawer': isDrawer }"
   [attr.data-component]="'ncx/search/page/filters'">
   <app-loader-component
      loaderType="SECTION_LOADER"
      size="default"
      [isLoaded]="!isLoading"></app-loader-component>

   <div class="sider-header">
      <span>Filters</span>
      <i
         nz-icon
         nzType="close"
         nzTheme="outline"
         (click)="close()"></i>
   </div>

   <app-scroll-container>
      <ng-template bodyTemplate>
         <div class="filter-body">
            <!-- ****************** Stories Filter ************************* -->

            <ng-container *ngIf="filterState === 'STORIES'">
               <!--
                    {{searchService.filters.STORIES | json}}
          -->

               <nz-collapse
                  class="margin-space"
                  nzExpandIconPosition="right"
                  [nzBordered]="false">
                  <nz-collapse-panel
                     [nzHeader]="followingHeader"
                     nzActive>
                     <nz-radio-group
                        [(ngModel)]="searchService.filters.STORIES.following"
                        (ngModelChange)="onFilterChanged()"
                        [nzButtonStyle]="'solid'">
                        <label
                           nz-radio-button
                           nzValue="All">
                           All
                        </label>
                        <label
                           nz-radio-button
                           nzValue="Following">
                           Following
                        </label>
                     </nz-radio-group>
                  </nz-collapse-panel>
               </nz-collapse>

               <nz-collapse
                  class="margin-space"
                  nzExpandIconPosition="right"
                  [nzBordered]="false">
                  <nz-collapse-panel
                     [nzHeader]="dateHeader"
                     nzActive>
                     <nz-range-picker
                        [(ngModel)]="searchService.filters.STORIES.modificationDate"
                        (ngModelChange)="onFilterChanged()"></nz-range-picker>
                  </nz-collapse-panel>
               </nz-collapse>

               <nz-collapse
                  class="margin-space"
                  nzExpandIconPosition="right"
                  [nzBordered]="false">
                  <nz-collapse-panel
                     [nzHeader]="orderHeader"
                     nzActive>
                     <section>
                        <p class="header">Modified Date</p>

                        <nz-radio-group
                           class="list"
                           [(ngModel)]="searchService.filters.STORIES.sort"
                           (ngModelChange)="onFilterChanged()">
                           <label
                              nz-radio
                              nzValue="newestFirst">
                              Newest First
                           </label>
                           <label
                              nz-radio
                              nzValue="oldestFirst">
                              Oldest First
                           </label>
                        </nz-radio-group>
                     </section>

                     <section>
                        <p class="header">Relevancy</p>

                        <nz-radio-group
                           class="list"
                           [(ngModel)]="searchService.filters.STORIES.sort"
                           (ngModelChange)="onFilterChanged()">
                           <label
                              nz-radio
                              nzValue="relevancescore">
                              Most Relevant
                           </label>
                        </nz-radio-group>
                     </section>

                     <section>
                        <p class="header">Followers</p>

                        <nz-radio-group
                           class="list"
                           [(ngModel)]="searchService.filters.STORIES.sort"
                           (ngModelChange)="onFilterChanged()">
                           <label
                              nz-radio
                              nzValue="mostFollowers">
                              Most to Least
                           </label>
                           <label
                              nz-radio
                              nzValue="leastFollowers">
                              Least to Most
                           </label>
                        </nz-radio-group>
                     </section>

                     <section>
                        <p class="header">Alphabetical</p>

                        <nz-radio-group
                           class="list"
                           [(ngModel)]="searchService.filters.STORIES.sort"
                           (ngModelChange)="onFilterChanged()">
                           <label
                              nz-radio
                              nzValue="A-Z">
                              A-Z
                           </label>
                           <label
                              nz-radio
                              nzValue="Z-A">
                              Z-A
                           </label>
                        </nz-radio-group>
                     </section>

                     <section>
                        <p class="header">Post Count</p>

                        <nz-radio-group
                           class="list"
                           [(ngModel)]="searchService.filters.STORIES.sort"
                           (ngModelChange)="onFilterChanged()">
                           <label
                              nz-radio
                              nzValue="mostPost">
                              Most to Least
                           </label>
                           <label
                              nz-radio
                              nzValue="leastPost">
                              Least to Most
                           </label>
                        </nz-radio-group>
                     </section>
                  </nz-collapse-panel>
               </nz-collapse>
            </ng-container>

            <!-- ****************** Posts Filter ************************* -->

            <ng-container *ngIf="filterState === 'POSTS'">
               <!--
                    {{searchService.filters.POSTS | json}}
          -->

               <nz-collapse
                  nzExpandIconPosition="right"
                  class="margin-space"
                  [nzBordered]="false">
                  <nz-collapse-panel
                     [nzHeader]="orderHeader"
                     nzActive>
                     <nz-radio-group
                        class="list"
                        [(ngModel)]="searchService.filters.POSTS.sort"
                        (ngModelChange)="onFilterChanged()"
                        [nzButtonStyle]="'solid'">
                        <label
                           nz-radio
                           nzValue="relevancescore">
                           Relevant
                        </label>
                        <label
                           nz-radio
                           nzValue="newestFirst">
                           Newest First
                        </label>
                        <label
                           nz-radio
                           nzValue="oldestFirst">
                           Oldest First
                        </label>
                     </nz-radio-group>
                  </nz-collapse-panel>
               </nz-collapse>

               <nz-collapse
                  class="margin-space"
                  nzExpandIconPosition="right"
                  [nzBordered]="false">
                  <nz-collapse-panel
                     [nzHeader]="dateHeader"
                     nzActive>
                     <nz-range-picker
                        [(ngModel)]="searchService.filters.POSTS.modificationDate"
                        (ngModelChange)="onFilterChanged()"></nz-range-picker>
                  </nz-collapse-panel>
               </nz-collapse>

               <nz-collapse
                  class="margin-space"
                  nzExpandIconPosition="right"
                  [nzBordered]="false">
                  <nz-collapse-panel
                     class="list"
                     [nzHeader]="elementsHeader"
                     nzActive>
                     <label
                        nz-checkbox
                        class="pb-8"
                        [(ngModel)]="searchService.filters.POSTS.isAllMediaChecked"
                        (ngModelChange)="selectMediaOption(true, $event)">
                        All
                     </label>

                     <nz-checkbox-wrapper class="list">
                        <label
                           nz-checkbox
                           *ngFor="let opt of searchService.filters.POSTS?.media"
                           [(ngModel)]="opt.checked"
                           (ngModelChange)="selectMediaOption(false, $event)">
                           {{ opt.label }}
                        </label>
                     </nz-checkbox-wrapper>
                  </nz-collapse-panel>
               </nz-collapse>

               <nz-collapse
                  class="margin-space"
                  nzExpandIconPosition="right"
                  [nzBordered]="false">
                  <nz-collapse-panel
                     [nzHeader]="editorialStandardsHeader"
                     nzActive>
                     <nz-checkbox-wrapper class="list">
                        <ng-container *ngFor="let opt of searchService.filters.POSTS?.editorial">
                           <label
                              nz-checkbox
                              *ngIf="opt.label !== 'None'"
                              [(ngModel)]="opt.checked"
                              (ngModelChange)="onFilterChanged()">
                              {{ opt.label }}
                           </label>
                        </ng-container>
                     </nz-checkbox-wrapper>
                  </nz-collapse-panel>
               </nz-collapse>

               <nz-collapse
                  class="margin-space"
                  nzExpandIconPosition="right"
                  [nzBordered]="false">
                  <nz-collapse-panel
                     [nzHeader]="rcLegalHeader"
                     nzActive>
                     <nz-checkbox-wrapper class="list">
                        <ng-container *ngFor="let opt of searchService.filters.POSTS?.legal">
                           <label
                              nz-checkbox
                              *ngIf="opt.label !== 'None'"
                              [(ngModel)]="opt.checked"
                              (ngModelChange)="onFilterChanged()">
                              {{ opt.label }}
                           </label>
                        </ng-container>
                     </nz-checkbox-wrapper>
                  </nz-collapse-panel>
               </nz-collapse>

               <nz-collapse
                  class="margin-space"
                  nzExpandIconPosition="right"
                  [nzBordered]="false">
                  <nz-collapse-panel
                     [nzHeader]="authorHeader"
                     nzActive>
                     <nz-input-group
                        [nzSuffix]="searchService.filters.POSTS.authorName ? suffixIconClear : suffixIconSearch">
                        <input
                           type="text"
                           class="filterAuthor"
                           nz-input
                           placeholder="Search Author..."
                           [nzAutocomplete]="auto"
                           [(ngModel)]="searchService.filters.POSTS.authorName"
                           (keydown)="onSearchAuthor($event)"
                           (keyup)="onSearchAuthor($event)"
                           (ngModelChange)="onSelectAuthor($event, 'POSTS')" />
                     </nz-input-group>
                     <nz-autocomplete #auto>
                        <ng-container *ngFor="let profile of profileOptions">
                           <nz-auto-option [nzValue]="profile.name">{{ profile.name }}</nz-auto-option>
                        </ng-container>
                        <nz-auto-option
                           *ngIf="
                              searchService.filters.POSTS.authorName !== '' &&
                              profileOptions.length === 0 &&
                              profileLoaded === true
                           ">
                           No Authors Found
                        </nz-auto-option>
                     </nz-autocomplete>
                  </nz-collapse-panel>
               </nz-collapse>
            </ng-container>

            <!-- ****************** Angles Filter ************************* -->

            <ng-container *ngIf="filterState === 'ANGLES'">
               <!--
                    {{searchService.filters.ANGLES | json}}
          -->

               <nz-collapse
                  nzExpandIconPosition="right"
                  class="margin-space"
                  [nzBordered]="false">
                  <nz-collapse-panel
                     [nzHeader]="orderHeader"
                     nzActive>
                     <nz-radio-group
                        class="list"
                        [(ngModel)]="searchService.filters.ANGLES.sort"
                        (ngModelChange)="onFilterChanged()"
                        [nzButtonStyle]="'solid'">
                        <ng-container *ngFor="let obj of angleSortOptions">
                           <label
                              nz-radio
                              [nzValue]="obj.value">
                              {{ obj.label }}
                           </label>
                        </ng-container>
                     </nz-radio-group>
                  </nz-collapse-panel>
               </nz-collapse>

               <!-- Filter BY PRIVACY -->
               <nz-collapse
                  nzExpandIconPosition="right"
                  nzBordered="false"
                  class="margin-space">
                  <nz-collapse-panel
                     [nzHeader]="privacyTpl"
                     nzActive="true"
                     class="elements">
                     <nz-radio-group
                        class="list"
                        [(ngModel)]="searchService.filters.ANGLES.privacy"
                        (ngModelChange)="onFilterChanged()"
                        [nzButtonStyle]="'solid'">
                        <ng-container *ngFor="let obj of anglePrivacyOrder">
                           <label
                              nz-radio
                              [nzValue]="obj.value">
                              {{ obj.label }}
                           </label>
                        </ng-container>
                     </nz-radio-group>
                  </nz-collapse-panel>
               </nz-collapse>

               <nz-collapse
                  class="margin-space"
                  nzExpandIconPosition="right"
                  [nzBordered]="false">
                  <nz-collapse-panel
                     [nzHeader]="authorHeader"
                     nzActive>
                     <nz-input-group
                        [nzSuffix]="searchService.filters.ANGLES.authorName ? suffixIconClear : suffixIconSearch">
                        <input
                           type="text"
                           class="filterAuthor"
                           nz-input
                           placeholder="Search Author..."
                           [nzAutocomplete]="auto"
                           [(ngModel)]="searchService.filters.ANGLES.authorName"
                           (keydown)="onSearchAuthor($event)"
                           (keyup)="onSearchAuthor($event)"
                           (ngModelChange)="onSelectAuthor($event, 'ANGLES')" />
                     </nz-input-group>
                     <nz-autocomplete #auto>
                        <ng-container *ngFor="let profile of profileOptions">
                           <nz-auto-option [nzValue]="profile.name">
                              {{ profile.name }}
                           </nz-auto-option>
                        </ng-container>
                        <nz-auto-option
                           *ngIf="
                              searchService.filters.ANGLES.authorName !== '' &&
                              profileOptions.length === 0 &&
                              profileLoaded === true
                           ">
                           No Authors Found
                        </nz-auto-option>
                     </nz-autocomplete>
                  </nz-collapse-panel>
               </nz-collapse>
            </ng-container>

            <!-- ****************** Groups Filter ************************* -->

            <ng-container *ngIf="filterState === 'GROUPS'">
               <!--
                    {{searchService.filters.GROUPS | json}}
          -->

               <nz-collapse
                  nzExpandIconPosition="right"
                  [nzBordered]="false">
                  <nz-collapse-panel
                     [nzHeader]="orderHeader"
                     nzActive>
                     <section>
                        <nz-radio-group
                           [(ngModel)]="searchService.filters.GROUPS.sort"
                           (ngModelChange)="onFilterChanged()"
                           [nzButtonStyle]="'solid'">
                           <label
                              nz-radio-button
                              nzValue="descending">
                              Newest First
                           </label>
                           <label
                              nz-radio-button
                              nzValue="ascending">
                              Oldest First
                           </label>
                        </nz-radio-group>
                     </section>
                  </nz-collapse-panel>
               </nz-collapse>
            </ng-container>

            <!-- ****************** People Filter ************************* -->

            <ng-container *ngIf="filterState === 'PEOPLE'">
               <nz-collapse
                  nzExpandIconPosition="right"
                  [nzBordered]="false">
                  <nz-collapse-panel
                     [nzHeader]="orderHeader"
                     nzActive>
                     <section>
                        <nz-radio-group
                           [(ngModel)]="searchService.filters.PEOPLE.sort"
                           (ngModelChange)="onFilterChanged()"
                           [nzButtonStyle]="'solid'">
                           <label
                              nz-radio-button
                              nzValue="ascending">
                              A-Z
                           </label>
                           <label
                              nz-radio-button
                              nzValue="descending">
                              Z-A
                           </label>
                        </nz-radio-group>
                     </section>
                  </nz-collapse-panel>
               </nz-collapse>
            </ng-container>

            <!-- CLEAR BUTTON -->
            <button
               *ngIf="filterState === 'STORIES' || filterState === 'POSTS' || filterState === 'ANGLES'"
               class="mb-24 clear-btn"
               nz-button
               nzBlock
               (click)="clearFilters()">
               <span
                  nz-icon
                  nzType="clear"
                  nzTheme="outline"></span>
               Clear
            </button>
         </div>
      </ng-template>
   </app-scroll-container>
</div>

<ng-template #orderHeader>
   <i
      nz-icon
      nzType="sort-ascending"></i>
   Sort By
</ng-template>

<ng-template #followingHeader>
   <i
      nz-icon
      nzType="star"></i>
   Following
</ng-template>

<ng-template #dateHeader>
   <i
      nz-icon
      nzType="calendar"></i>
   Modification Date
</ng-template>

<ng-template #editorialStandardsHeader>
   <i
      nz-icon
      nzType="audit"></i>
   Editorial/Standards
</ng-template>

<ng-template #rcLegalHeader>
   <i
      nz-icon
      nzType="trademark-circle"></i>
   R&C/Legal
</ng-template>

<ng-template #elementsHeader>
   <i
      nz-icon
      nzType="file-image"></i>
   Elements
</ng-template>

<ng-template #authorHeader>
   <i
      nz-icon
      nzType="user"></i>
   Author
</ng-template>

<ng-template #suffixIconSearch>
   <i
      nz-icon
      nzType="search"></i>
</ng-template>

<ng-template #suffixIconClear>
   <button (click)="onClearAuthor()">
      <i
         nz-icon
         nzType="close-circle"></i>
   </button>
</ng-template>

<ng-template #privacyTpl>
   <span class="filter-collapse-header">
      <i
         nz-icon
         nzType="eye"
         nzTheme="outline"></i>
      Privacy
   </span>
</ng-template>
