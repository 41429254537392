import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@environments/environment';
import { ISearch } from '@models/ncx/global-search';
import { TranslateService } from '@ngx-translate/core';
import { CommonFunctionsHelper } from '@services/auth/comon.functions.helper';
import * as lodash from 'lodash';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { CommonService } from 'src/app/services/common-service';
import { ToastService } from 'src/app/services/toastService/toastMessage.service';

@Component({
  selector: 'app-custom-column',
  templateUrl: './custom-column.component.html',
  styleUrls: ['../customization.component.scss']
})
export class CustomColumnComponent implements OnInit {

  isLoaded = true;

  newColumnName = '';

  categories: any;

  id = '';

  isDisable = true;

  editLabels: any[];

  searchInput = '';

  categoryName = '';

  searchInputTopic = '';

  searchInputStory = '';

  notification = 'Notifications Off';

  tabs = [
    {
      itemName: 'List',
      users: [],
      count: 0,
      groups: []
    },
    {
      itemName: 'Following',
      users: [],
      count: 0
    }
  ];

  /* Activity Declaration start */
  storiesList: any[];

  Stories = [
    {
      itemName: 'List',
      stories: [],
      count: 0
    },
    {
      itemName: 'Following',
      stories: [],
      count: 0
    }
  ];

  TopicsList: any[];

  Topics = [
    {
      itemName: 'Added Topics',
      users: [],
      count: 0
    },
    {
      itemName: 'Topics',
      users: [],
      count: 0
    }
  ];

  Labels = [
    {
      itemName: 'Added Labels',
      labels: [],
      count: 0
    },
    {
      itemName: 'Labels',
      labels: [],
      count: 0
    }
  ];

  /* Activity Declaration End */
  searchTextLabel = 'Search for a Person...';

  searchTextLabelHeader = 'Add a Person:';

  PostType = '';

  Tag = '';

  Tags = [];

  disable = true;

  disableTopic = true;

  disableClear = true;

  disableClearTopic = true;

  disableClearAll = true;

  disableClearAllTopic = true;

  selectedGroupPersonIndex = 1;

  @Output() close: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private cS: CommonService,
    private toastService: ToastService,
    private router: Router,
    private commonFunctionsHelper: CommonFunctionsHelper,
    private Translate: TranslateService
  ) {

    this.globalSearch = lodash.debounce(this.globalSearch, 1000);

  }

  ngOnInit() {

    this.isLoaded = false;

    this.cS.serviceRequestCommon('get', environment.getMetaDataAPI + '/categories').subscribe((res: any) => {

      this.categories = res;

    }, err => {

      this.toastService.createMessage('error', err);

    }).add(() => {

      this.isLoaded = true;

    });

    this.cS.clearAddColumnData.subscribe(() => {

      this.cancelAddColumn();

    });

  }

  closeDrawer() {

    this.cancelAddColumn();
    this.close.emit();

  }

  globalSearch(event, Category) {

    if (Category === 'Topic') {

      const text = event.target.value;

      const pageStartCount = 1;
      const pageLength = 1000;
      const queryStr = `?topicName=${encodeURIComponent(text)}&&excludeDeleted=true&pageStartCount=${pageStartCount}&pageLength=${pageLength}`;

      this.cS.serviceRequestCommon('get', environment.getAllTopicsAPIURL, queryStr).pipe(
        debounceTime(1),
        distinctUntilChanged()
      ).subscribe((res: any) => {

        this.TopicsList = res.topics;
        this.isLoaded = true;

      },
        (err) => {

          this.toastService.createMessage('error', err);
          this.isLoaded = true;

        });

    } else if (Category === 'Story') {

      const text = event.target.value;

      const queryStr = `?storyTitle=${encodeURIComponent(text)}`;

      this.cS.serviceRequestCommon('get', environment.getStoryTitles, queryStr).pipe(
        debounceTime(1),
        distinctUntilChanged()
      ).subscribe((res: any) => {

        this.storiesList = res;
        this.isLoaded = true;

      },
        (err) => {

          this.toastService.createMessage('error', err);
          this.isLoaded = true;

        });

    } else {

      if (Category === 'Person') {

        const text = event.target.value;

        const userId = localStorage.getItem('userId');

        const queryStr = `?targetUserId=${userId}&userName=${text}`;

        this.cS.serviceRequestCommon('get', environment.getusersAndConnectionsURL, queryStr).pipe(
          debounceTime(1),
          distinctUntilChanged()
        ).subscribe((res: any) => {

          const list = [];

          if (Category === 'Person') {

            res[0].users.forEach(element => {

              list.push({
                fullName: element.fullName,
                userId: element.userId,
                ssoId: element.ssoId
              });

            });
            this.editLabels = list;

          }

          this.isLoaded = true;

        },
          (err) => {

            this.toastService.createMessage('error', err);
            this.isLoaded = true;

          });

      } else {

        const text = event.target.value;

        const queryStr = `?searchString=${encodeURIComponent(text)}&isSuggestNeeded=false` +
          '&sortField=modificationdate&sortOrder=descending&sartIndex=1&endIndex=10';

        this.cS.serviceRequestCommon('post', environment.globalSearch, queryStr).pipe(
          debounceTime(1),
          distinctUntilChanged()
        ).subscribe((res: any) => {

          const list = [];

          const result = res as ISearch;

          if (Category === 'Person') {

            result.profileResult.forEach(element => {

              list.push({
                fullName: element.displayName,
                userId: element.userId,
                ssoId: element.ssoId
              });

            });
            this.editLabels = list;

          }
          if (Category === 'Group') {

            result.groupSearchResult.forEach(element => {

              list.push({
                groupName: element.storyTitle,
                fullName: element.storyTitle,
                groupId: element.storyID
              });

            });
            this.editLabels = list;
            console.log('group inside global search', this.editLabels);

          }
          if (Category === 'Activity') {

            res.storySearchResult.forEach(element => {

              list.push({
                storyId: element.storyID,
                storyTitle: element.storyTitle
              });

            });
            this.storiesList = list;

          }
          this.isLoaded = true;

        },
          (err) => {

            this.toastService.createMessage('error', err);
            this.isLoaded = true;

          });

      }

    }

  }

  selectbox(event: any) {

    this.isLoaded = false;
    this.selectedGroupPersonIndex = 1;

    this.id = event;
    this.categoryName = event;
    this.isDisable = true;

    if (event === 'Person') {

      this.searchTextLabel = 'Search for a Person...';
      this.searchTextLabelHeader = 'Add a Person:';
      const userId = localStorage.getItem('userId');

      const queryStr = `?targetUserId=${userId}`;

      this.cS.serviceRequestCommon('get', environment.getusersAndConnectionsURL, queryStr).subscribe((res: any) => {

        res.forEach((item: any) => {

          if (item.itemName === 'UsersList') {

            this.editLabels = item.users;

          }
          if (item.itemName === 'FollowersList') {

            this.tabs = [
              {
                itemName: 'List',
                users: [],
                count: 0
              }
            ];
            item.itemName = 'Following';
            this.tabs.push(item);
            if (this.tabs[1].count > 0) {

              this.disableClearAll = false;

            }

          }
          this.isLoaded = true;

        });

      },
        (err) => {

          this.toastService.createMessage('error', err);
          this.isLoaded = true;

        });

    }

    if (event === 'Group') {

      this.searchTextLabel = 'Search for a Group...';
      this.searchTextLabelHeader = 'Add a Group:';
      this.cS.serviceRequestCommon('get', environment.getMYGroupsURL).subscribe((res: any) => {

        this.tabs = [
          {
            itemName: 'List',
            users: [],
            count: 0
          }
        ];

        // tslint:disable-next-line:prefer-const
        res.itemName = 'My Groups';
        if (res && res.groups && res.groups.length > 0) {

          res.count = res.groups.length;

        } else {

          res.count = 0;

        }
        res.users = res.groups === null ? [] : res.groups;
        this.tabs.push(res);
        this.isLoaded = true;
        if (this.tabs[1].count > 0) {

          this.disableClearAll = false;

        }

      },
        (err) => {

          this.toastService.createMessage('error', err);
          this.isLoaded = true;

        });

      this.cS.serviceRequestCommon('get', environment.getALLGroupsURL).subscribe((res: any) => {

        this.editLabels = res.groups;
        this.isLoaded = true;

      },
        (err) => {

          this.toastService.createMessage('error', err);
          this.isLoaded = true;

        });

    }

    if (event === 'Activity') {

      this.PostType = 'All';
      this.isDisable = false;
      const userId = localStorage.getItem('userId');

      const ssoId = localStorage.getItem('ssoId');

      const queryStr = `?followerSSOId=${ssoId}&isFollowerId=true`;

      this.cS.serviceRequestCommon('get', '/api/v1/gateway/searchById', queryStr).subscribe((res: any) => {

        res.itemName = 'Following';
        this.Stories[1].count = res.totalCount;
        this.Stories[1].stories = res.stories;
        this.isLoaded = true;
        if (this.Stories[1].count > 0) {

          this.disableClearAll = false;

        }

      },
        (err) => {

          this.toastService.createMessage('error', err);
          this.isLoaded = true;

        });
      this.cS.serviceRequestCommon('post', environment.getALLStories).subscribe((res: any) => {

        this.storiesList = res.storySearchResult;
        this.isLoaded = true;

      },
        (err) => {

          this.toastService.createMessage('error', err);
          this.isLoaded = true;

        });
      const pageStartCount = 1;
      const pageLength = 1000;
      const queryStrTopic = `?excludeDeleted=true&pageStartCount=${pageStartCount}&pageLength=${pageLength}`;
      this.cS.serviceRequestCommon('get', environment.getAllTopicsAPIURL, queryStrTopic).subscribe((res: any) => {

        this.TopicsList = res.topics;
        this.isLoaded = true;

      },
        (err) => {

          this.toastService.createMessage('error', err);
          this.isLoaded = true;

        });

      /* Follower Topic section start */
      const FollowqueryStr = `/FollowingTopics/${userId}`;

      this.cS.serviceRequestCommon('get', environment.getFollowTopicsAPIURL, FollowqueryStr).subscribe((res: any) => {

        this.Topics[1].users = res.followingTopicIDs;
        this.Topics[1].count = res.topicFollowingCount;
        this.isLoaded = true;
        if (this.Topics[1].count > 0) {

          this.disableClearAllTopic = false;

        }

      },
        (err) => {

          this.toastService.createMessage('error', err);
          this.isLoaded = true;

        });

      /* Follower Topic section End */
      this.cS.serviceRequestCommon('get', environment.getALLLabels).subscribe((res: any) => {

        this.Labels[1].labels = res;
        this.Labels[1].count = res.length;
        this.isLoaded = true;

      },
        (err) => {

          this.toastService.createMessage('error', err);
          this.isLoaded = true;

        });

    }
    this.searchInput = '';
    this.searchInputStory = '';
    this.searchInputTopic = '';

  }

  selectPostType(item, idName) {

    this.isDisable = false;

    if (idName === 'AllPostType') {

      this.PostType = 'All';

    }
    if (idName === 'PostsPostType') {

      this.PostType = 'Posts';

    }
    if (idName === 'ElementsPostType') {

      this.PostType = 'Elements';

    }
    if (idName === 'SummariesPostType') {

      this.PostType = 'Summaries';

    }

  }

  changeLabelStatus(data) {

    this.isDisable = false;
    this.disableClearAll = false;
    this.disableClear = true;
    const isExists = this.containsObject(data, this.tabs[0].users);

    if (!isExists) {

      data.checked = false;
      this.tabs[0].users.push({ ...data });
      this.tabs[0].count = this.tabs[0].users.length;

      // open the first tab to show the list
      this.selectedGroupPersonIndex = 0;

    } else {

      this.toastService.createMessage('error', 'Selected Resource already exists in list.');

    }
    setTimeout(() => {

      // clear input box
      this.searchInput = '';

      // blur so that the user can select another value
      const inputBox = document.getElementById('groupPersonValue') as HTMLInputElement;

      if (inputBox) {

        inputBox.blur();

      }

    }, 0);

  }

  SelectCheckbox(TabName, objitem, checked) {

    this.disableClearAll = false;
    this.disableClear = false;
    this.isLoaded = false;

    // tslint:disable-next-line:only-arrow-functions
    const self = this;

    this.tabs.filter((item, itemindex) => {

      if (self.categoryName === 'Person') {

        // tslint:disable-next-line:only-arrow-functions
        if ((TabName === 'List' && item.itemName === TabName)) {

          self.tabs[itemindex].users.filter((subitem, subindex) => {

            if (subitem.userId === objitem.userId) {

              checked = (checked === undefined || checked === false || checked === '') ? false : true;
              self.tabs[itemindex].users[subindex].checked = !checked;

            }

          });

        }
        if ((TabName === 'Following' && item.itemName === TabName)) {

          self.tabs[itemindex].users.filter((subitem, subindex) => {

            if (subitem.userId === objitem.userId) {

              checked = (checked === undefined || checked === false || checked === '') ? false : true;
              self.tabs[itemindex].users[subindex].checked = !checked;

            }

          });

        }

      }

      if (self.categoryName === 'Group') {

        if (item.itemName === 'List' && item.itemName === TabName) {

          item.users.filter((subitem, subindex) => {

            if (subitem.groupId === objitem.groupId) {

              checked = (checked === undefined || checked === false || checked === '') ? false : true;
              item.users[subindex].checked = !checked;

            }

          });

        }
        if (item.itemName === 'My Groups' && item.itemName === TabName) {

          item.groups.filter((subitem, subindex) => {

            if (subitem.groupId === objitem.groupId) {

              checked = (checked === undefined || checked === false || checked === '') ? false : true;
              item.groups[subindex].checked = !checked;

            }

          });

        }

      }
      this.isLoaded = true;

    });

  }

  SelectAllOptions(TabName) {

    this.isDisable = false;
    this.disableClear = false;

    // tslint:disable-next-line:only-arrow-functions
    this.tabs.filter((item) => {

      if (item.itemName === TabName) {

        if (TabName === 'My Groups') {

          // tslint:disable-next-line:only-arrow-functions
          item.groups.filter((subitem, subindex) => {

            item.groups[subindex].checked = true;

          });

        } else {

          // tslint:disable-next-line:only-arrow-functions
          item.users.filter((subitem, subindex) => {

            item.users[subindex].checked = true;

          });

        }

      }

    });

  }

  ClearSelectAllOptions(buttonName, TabName) {

    this.disableClear = true;

    if (buttonName === 'clear-button') {

      // tslint:disable-next-line:one-variable-per-declaration
      // tslint:disable-next-line:no-shadowed-variable
      let searchInput: any;

      // tslint:disable-next-line:no-shadowed-variable
      let i: any;

      // tslint:disable-next-line:no-shadowed-variable
      let count: any;

      searchInput = Object.create(this.tabs);
      count = searchInput.length;
      while (count--) {

        if (searchInput[count].itemName === TabName) {

          i = searchInput[count].users.length;

          while (i--) {

            {

              if (searchInput[count].users[i].checked === true) {

                searchInput[count].users.splice(i, 1);

              }

            }

          }

        }

      }
      this.tabs[0].count = this.tabs[0].users.length;

    } else {

      // tslint:disable-next-line:no-shadowed-variable
      let searchInput: any;

      // tslint:disable-next-line:no-shadowed-variable
      let i: any;

      // tslint:disable-next-line:no-shadowed-variable
      let count: any;

      searchInput = Object.create(this.tabs);
      count = searchInput.length;
      while (count--) {

        if (searchInput[count].itemName === TabName) {

          if (TabName === 'My Groups') {

            i = searchInput[count].groups.length;
            while (i--) {

              {

                if (searchInput[count].groups[i].checked === true) {

                  searchInput[count].groups[i].checked = false;
                  const objectData = Object.assign({}, searchInput[count].groups[i]);

                  const isContains = this.containsObject(objectData, this.tabs[0].users);

                  if (!isContains) {

                    this.tabs[0].users.push({ ...searchInput[count].groups[i] });
                    this.tabs[0].count = this.tabs[0].users.length;
                    this.isDisable = false;

                  } else {

                    this.toastService.createMessage('error', 'Selected resource already exist in list.');

                  }

                }

              }

            }

          }
          if (TabName === 'Following') {

            i = searchInput[count].users.length;
            while (i--) {

              {

                if (searchInput[count].users[i].checked === true) {

                  searchInput[count].users[i].checked = false;
                  const objectData = Object.assign({}, searchInput[count].users[i]);

                  const isContains = this.containsObject(objectData, this.tabs[0].users);

                  if (!isContains) {

                    this.tabs[0].users.push({ ...searchInput[count].users[i] });
                    this.tabs[0].count = this.tabs[0].users.length;
                    this.isDisable = false;

                  } else {

                    this.toastService.createMessage('error', 'Selected resource already exist in list.');

                  }

                }

              }

            }

          }

        }

      }
      this.tabs[0].count = this.tabs[0].users.length;

    }
    if (this.tabs[0].users.length === 0) {

      this.disableClearAll = true;

    }

  }

  containsObject(obj, list) {

    let i;

    for (i = 0; i < list.length; i++) {

      if (this.categoryName === 'Person') {

        if ((list[i].ssoId === obj.ssoId && list[i].userId === obj.userId)) {

          return true;

        }

      }
      if (this.categoryName === 'Group') {

        if ((list[i].groupId === obj.groupId && list[i].groupName === obj.groupName)) {

          return true;

        }

      }
      if (this.categoryName === 'Activity') {

        if ((list[i].topicId === obj.topicId && list[i].topicName === obj.topicName)) {

          return true;

        }

      }

    }
    return false;

  }

  containsLabelObject(obj, list) {

    console.log('contains', this.categoryName, 'obj', obj, 'list', list);
    let i;

    for (i = 0; i < list.length; i++) {

      if (this.categoryName === 'Activity') {

        if ((list[i].label === obj.label && list[i].value === obj.value)) {

          return true;

        }

      }

    }
    return false;

  }

  isActivitycontainsObject(obj, list, TagName) {

    let i;

    for (i = 0; i < list.length; i++) {

      if (TagName === 'Story') {

        if ((list[i].storyId === obj.storyId && list[i].storyTitle === obj.storyTitle)) {

          return true;

        }

      }

    }
    return false;

  }

  enableNotification(_event: any) {

    if (this.notification === 'Notifications Off') {

      this.notification = 'Notifications On';

    } else {

      this.notification = 'Notifications Off';

    }

  }

  clearFilters() {

    this.searchInputTopic = '';
    this.searchInputStory = '';
    this.newColumnName = '';
    this.searchInput = '';
    this.isDisable = true;
    this.disable = true;
    this.disableTopic = true;
    this.disableClear = true;
    this.disableClearTopic = true;
    this.PostType = '';
    this.tabs[0].users = [];
    this.tabs[0].count = 0;
    this.categoryName = '';
    this.notification = 'Notifications Off';
    this.id = '';
    this.Labels = [
      {
        itemName: 'Added Labels',
        labels: [],
        count: 0
      },
      {
        itemName: 'Labels',
        labels: [],
        count: 0
      }
    ];
    this.storiesList = [];
    this.Stories = [
      {
        itemName: 'List',
        stories: [],
        count: 0
      },
      {
        itemName: 'Following',
        stories: [],
        count: 0
      }
    ];
    this.TopicsList = [];
    this.Topics = [
      {
        itemName: 'Added Topics',
        users: [],
        count: 0
      },
      {
        itemName: 'Topics',
        users: [],
        count: 0
      }
    ];
    this.Tags = [];
    this.searchInput = '';
    this.searchInputStory = '';
    this.searchInputTopic = '';
    this.Tag = '';

  }

  cancelAddColumn() {

    this.searchInputTopic = '';
    this.searchInputStory = '';
    this.newColumnName = '';
    this.searchInput = '';
    this.isDisable = true;
    this.disable = true;
    this.disableTopic = true;
    this.disableClear = true;
    this.disableClearTopic = true;
    this.PostType = '';
    this.tabs[0].users = [];
    this.tabs[0].count = 0;
    this.categoryName = '';
    this.notification = 'Notifications Off';
    this.id = '';

    this.Labels = [
      {
        itemName: 'Added Labels',
        labels: [],
        count: 0
      },
      {
        itemName: 'Labels',
        labels: [],
        count: 0
      }
    ];
    this.storiesList = [];
    this.Stories = [
      {
        itemName: 'List',
        stories: [],
        count: 0
      },
      {
        itemName: 'Following',
        stories: [],
        count: 0
      }
    ];

    this.TopicsList = [];
    this.Topics = [
      {
        itemName: 'Added Topics',
        users: [],
        count: 0
      },
      {
        itemName: 'Topics',
        users: [],
        count: 0
      }
    ];
    this.Tags = [];
    this.searchInput = '';
    this.searchInputStory = '';
    this.searchInputTopic = '';
    this.Tag = '';

  }

  AddColumn() {

    if ((this.categoryName !== '' && this.categoryName !== undefined && this.categoryName !== null) &&
      (this.newColumnName !== '' && this.newColumnName !== undefined && this.newColumnName !== null)
    ) {

      if ((this.categoryName === 'Person' && this.tabs[0].users.length > 0) ||
        (this.categoryName === 'Group' && this.tabs[0].users.length > 0) ||
        (this.categoryName === 'Activity' && (this.Stories[0].stories.length > 0
          || this.Labels[0].labels.length > 0 || this.Topics[0].users.length > 0
          || (this.PostType !== undefined && this.PostType !== '' && this.PostType !== null)))) {

        this.isLoaded = false;
        const ssoid = [];

        const groupids = [];

        const stories = [];

        const labelsList = [];

        const topicslst = [];

        if (this.categoryName === 'Person') {

          this.tabs.forEach(item => {

            if (item.itemName === 'List') {

              item.users.forEach(subelement => {

                ssoid.push(subelement.ssoId);

              });

            }

          });

        } else if (this.categoryName === 'Group') {

          this.tabs.forEach(item => {

            if (item.itemName === 'List') {

              item.users.forEach(subelement => {

                groupids.push(subelement.groupId);

              });

            }

          });

        } else if (this.categoryName === 'Activity') {

          this.Stories.forEach(item => {

            if (item.itemName === 'List') {

              item.stories.forEach(subelement => {

                stories.push(subelement.storyID === undefined ? subelement.storyId : subelement.storyID);

              });

            }

          });
          this.Labels.forEach(item => {

            if (item.itemName === 'Added Labels') {

              item.labels.forEach(subelement => {

                labelsList.push(subelement.value);

              });

            }

          });
          this.Topics.forEach(item => {

            if (item.itemName === 'Added Topics') {

              item.users.forEach(subelement => {

                topicslst.push(subelement.topicId);

              });

            }

          });

        }

        const userId = localStorage.getItem('userId');

        const queryStr = `/${userId}`;

        this.cS.serviceRequestCommon('get', environment.getProfileAPIURL, queryStr).subscribe((res: any) => {

          const response = res;

          let enableNotify = false;

          if (this.notification === 'Notifications Off') {

            enableNotify = false;

          }
          if (this.notification === 'Notifications On') {

            enableNotify = true;

          }
          let colNumber = 0;

          response.personalizedColumns.forEach((item) => {

            if (item.columnNumber > colNumber) {

              colNumber = item.columnNumber;

            }

          });
          const dataToSave = {
            categoryName: this.categoryName,
            columnName: this.newColumnName,
            columnNumber: colNumber + 1,
            storyIds: stories,
            postIds: null,
            personIds: ssoid,
            groupIds: groupids,
            enabledNotifications: enableNotify,
            sortField: null,
            pageType: 'StoryDetails',
            postType: this.PostType === 'Posts' ? 'Update' :
              this.PostType === 'All' ? 'All' :
                this.PostType === 'Elements' ? 'Element' :
                  this.PostType === 'Summaries' ? 'Summary' : '',
            readyStory: false,
            workingStory: false,
            labels: labelsList,
            tags: this.Tags,
            topics: topicslst
          };

          // tslint:disable-next-line:no-shadowed-variable
          const queryStr = `/${userId}/userPersonalizedColumn`;

          this.cS.serviceRequestCommon('post', environment.getProfileAPIURL, queryStr, dataToSave).subscribe(() => {

            this.toastService.createMessage('success', 'Customized column successfully added.');
            this.isLoaded = true;
            this.cancelAddColumn();
            this.cS.UpdateDashboard.next('true');
            this.closeDrawer();

          }, () => {

            this.toastService.createMessage('error', this.Translate.instant('toaster.toastMessageError'));

          }).add(() => {

            this.isLoaded = true;

          });

        }, err => {

          this.toastService.createMessage('error', err);
          this.isLoaded = true;

        });

      } else {

        if (this.categoryName === 'Person') {

          this.toastService.createMessage('error', 'Please select atleast one person to list.');

        }
        if (this.categoryName === 'Group') {

          this.toastService.createMessage('error', 'Please select atleast one group to list.');

        }
        if (this.categoryName === 'Activity' && this.Stories[0].stories.length <= 0
          && this.Labels[0].labels.length <= 0 && this.Topics[0].users.length <= 0
          && (this.PostType === undefined || this.PostType === '' || this.PostType === null)) {

          this.toastService.createMessage('error', 'Please select any one from Post Type, Story, Topic or Label.');

        }
        this.isLoaded = true;

      }

    } else {

      if ((this.newColumnName === '' || this.newColumnName === undefined || this.newColumnName == null)) {

        this.toastService.createMessage('error', 'Please enter column name.');

      } else {

        this.toastService.createMessage('error', 'Please select category name.');

      }
      this.isLoaded = true;

    }

  }

  onChange(value: string, tag: string): void {

    console.log(tag, value);

    if (tag === 'Story' && typeof (value) === 'object') {

      this.changeStoriesLabelStatus(value, 'Story');

    } else if (tag === 'Person' && typeof (value) === 'object') {

      this.changeLabelStatus(value);

    } else if (tag === 'Topic' && typeof (value) === 'object') {

      this.changeTopicsLabelStatus(value);

    }

  }

  /* Activity Event Section Start */

  ClearSelectAllStoriesOptions(buttonName, TabName) {

    this.disableClear = true;
    if (buttonName === 'clear-button') {

      let searchInput: any;

      let i: any;

      let count: any;

      searchInput = Object.create(this.Stories);
      count = searchInput.length;
      while (count--) {

        if (searchInput[count].itemName === TabName) {

          i = searchInput[count].stories.length;
          while (i--) {

            {

              if (searchInput[count].stories[i].checked === true) {

                searchInput[count].stories.splice(i, 1);

              }

            }

          }

        }

      }
      this.Stories[0].count = this.Stories[0].stories.length;

    } else {

      let searchInput: any;

      let i: any;

      let count: any;

      searchInput = Object.create(this.Stories);
      count = searchInput.length;
      while (count--) {

        if (searchInput[count].itemName === TabName) {

          if (TabName === 'Following') {

            i = searchInput[count].stories.length;
            while (i--) {

              {

                if (searchInput[count].stories[i].checked === true) {

                  searchInput[count].stories[i].checked = false;
                  const objectData = Object.assign({}, searchInput[count].stories[i]);

                  const isContains = this.isActivitycontainsObject(objectData, this.Stories[0].stories, 'Story');

                  if (!isContains) {

                    this.Stories[0].stories.push({ ...searchInput[count].stories[i] });
                    this.Stories[0].count = this.Stories[0].stories.length;

                  } else {

                    this.toastService.createMessage('error', 'Selected resource already exist in list.');

                  }

                }

              }

            }

          }

        }

      }
      this.Stories[0].count = this.Stories[0].stories.length;

    }
    if (this.Stories[0].stories.length === 0) {

      this.disableClearAll = true;

    }

  }

  changeStoriesLabelStatus(data, TagName) {

    this.disable = false;
    this.disableClearAll = false;
    this.disableClear = true;
    const isExists = this.isActivitycontainsObject(data, this.Stories[0].stories, TagName);

    if (!isExists) {

      data.checked = false;
      this.Stories[0].stories.push({ ...data });
      this.Stories[0].count = this.Stories[0].stories.length;

    } else {

      this.toastService.createMessage('error', 'Selected Story already exists in list.');

    }
    setTimeout(() => {

      // clear input box
      this.searchInputStory = '';

      // blur so that the user can select another value
      const inputBox = document.getElementById('storyValue') as HTMLInputElement;

      if (inputBox) {

        inputBox.blur();

      }

    }, 0);

  }

  SelectAllStoriesOptions(TabName) {

    this.disableClear = false;

    this.Stories.filter((item) => {

      if (item.itemName === TabName) {

        item.stories.filter((subitem, subindex) => {

          item.stories[subindex].checked = true;

        });

      }

    });

  }

  SelectStoriesCheckbox(TabName, objitem, checked) {

    this.disableClear = false;
    this.isLoaded = false;
    const self = this;

    this.Stories.filter((item, itemindex) => {

      if ((TabName === 'List' && item.itemName === TabName)) {

        self.Stories[itemindex].stories.filter((subitem, subindex) => {

          if ((subitem.storyID || subitem.storyId) === (objitem.storyId || objitem.storyID)) {

            checked = (checked === undefined || checked === false || checked === '') ? false : true;
            self.Stories[itemindex].stories[subindex].checked = !checked;

          }

        });

      }
      if ((TabName === 'Following' && item.itemName === TabName)) {

        self.Stories[itemindex].stories.filter((subitem, subindex) => {

          if ((subitem.storyID || subitem.storyId) === (objitem.storyId || objitem.storyID)) {

            checked = (checked === undefined || checked === false || checked === '') ? false : true;
            self.Stories[itemindex].stories[subindex].checked = !checked;

          }

        });

      }
      self.isLoaded = true;

    });

  }

  ClearSelectAllTopicsOptions(buttonName, TabName) {

    this.disableClearTopic = true;
    if (buttonName === 'clear-button') {

      let searchInput: any;

      let i: any;

      let count: any;

      searchInput = Object.create(this.Topics);
      count = searchInput.length;
      while (count--) {

        if (searchInput[count].itemName === TabName) {

          i = searchInput[count].users.length;
          while (i--) {

            {

              if (searchInput[count].users[i].checked === true) {

                searchInput[count].users.splice(i, 1);

              }

            }

          }

        }

      }
      this.Topics[0].count = this.Topics[0].users.length;

    } else {

      let searchInput: any;

      let i: any;

      let count: any;

      searchInput = Object.create(this.Topics);
      count = searchInput.length;
      while (count--) {

        if (searchInput[count].itemName === TabName) {

          if (TabName === 'Topics') {

            i = searchInput[count].users.length;
            while (i--) {

              {

                if (searchInput[count].users[i].checked === true) {

                  searchInput[count].users[i].checked = false;
                  const objectData = Object.assign({}, searchInput[count].users[i]);

                  const isContains = this.containsObject(objectData, this.Topics[0].users);

                  if (!isContains) {

                    this.Topics[0].users.push({ ...searchInput[count].users[i] });
                    this.Topics[0].count = this.Topics[0].users.length;

                  } else {

                    this.toastService.createMessage('error', 'Selected resource already exist in list.');

                  }

                }

              }

            }

          }

        }

      }
      this.Topics[0].count = this.Topics[0].users.length;

    }
    if (this.Topics[0].users.length === 0) {

      this.disableClearAllTopic = true;

    }

  }

  changeTopicsLabelStatus(data) {

    this.disable = false;
    this.disableClearAllTopic = false;
    this.disableClearTopic = true;
    const isExists = this.containsObject(data, this.Topics[0].users);

    if (!isExists) {

      data.checked = false;
      this.Topics[0].users.push({ ...data });
      this.Topics[0].count = this.Topics[0].users.length;

    } else {

      this.toastService.createMessage('error', 'Selected Topic already exists in list.');

    }
    setTimeout(() => {

      // clear input box
      this.searchInputTopic = '';

      // blur so that the user can select another value
      const inputBox = document.getElementById('topicValue') as HTMLInputElement;

      if (inputBox) {

        inputBox.blur();

      }

    }, 0);

  }

  SelectAllTopicsOptions(TabName) {

    this.disableTopic = false;
    this.disableClearTopic = false;

    // tslint:disable-next-line:only-arrow-functions
    this.Topics.filter((item) => {

      if (item.itemName === TabName) {

        // tslint:disable-next-line:only-arrow-functions
        item.users.filter((subitem, subindex) => {

          item.users[subindex].checked = true;

        });

      }

    });

  }

  SelectTopicsCheckbox(TabName, objitem, checked) {

    this.disableClearTopic = false;
    this.isLoaded = false;
    const self = this;

    this.Topics.filter((item, itemindex) => {

      if ((TabName === 'Added Topics' && item.itemName === TabName)) {

        self.Topics[itemindex].users.filter((subitem, subindex) => {

          if (subitem.topicId === objitem.topicId) {

            checked = (checked === undefined || checked === false || checked === '') ? false : true;
            self.Topics[itemindex].users[subindex].checked = !checked;

          }

        });

      }
      if ((TabName === 'Topics' && item.itemName === TabName)) {

        self.Topics[itemindex].users.filter((subitem, subindex) => {

          if (subitem.topicId === objitem.topicId) {

            checked = (checked === undefined || checked === false || checked === '') ? false : true;
            self.Topics[itemindex].users[subindex].checked = !checked;

          }

        });

      }
      self.isLoaded = true;

    });

  }

  ClearSelectAllLabelsOptions(buttonName, TabName) {

    if (buttonName === 'clear-button') {

      let searchInput: any;

      let i: any;

      let count: any;

      searchInput = Object.create(this.Labels);
      count = searchInput.length;
      while (count--) {

        if (searchInput[count].itemName === TabName) {

          i = searchInput[count].labels.length;
          while (i--) {

            {

              if (searchInput[count].labels[i].checked === true) {

                searchInput[count].labels.splice(i, 1);

              }

            }

          }

        }

      }
      this.Labels[0].count = this.Labels[0].labels.length;

    } else {

      let searchInput: any;

      let i: any;

      let count: any;

      searchInput = Object.create(this.Labels);
      count = searchInput.length;
      while (count--) {

        if (searchInput[count].itemName === TabName) {

          i = searchInput[count].labels.length;
          while (i--) {

            {

              if (searchInput[count].labels[i].checked === true) {

                searchInput[count].labels[i].checked = false;
                const objectData = Object.assign({}, searchInput[count].labels[i]);

                const isContains = this.containsLabelObject(objectData, this.Labels[0].labels);

                if (!isContains) {

                  this.Labels[0].labels.push({ ...searchInput[count].labels[i] });
                  this.Labels[0].count = this.Labels[0].labels.length;

                } else {

                  this.toastService.createMessage('error', 'Selected resource already exist in list.');

                }

              }

            }

          }

        }

      }
      this.Labels[0].count = this.Labels[0].labels.length;

    }

  }

  SelectAllLabelsOptions(TabName) {

    // tslint:disable-next-line:only-arrow-functions
    this.Labels.filter((item) => {

      if (item.itemName === TabName) {

        // tslint:disable-next-line:only-arrow-functions
        item.labels.filter((subitem, subindex) => {

          item.labels[subindex].checked = true;

        });

      }

    });

  }

  SelectLabelsCheckbox(TabName, objitem, checked) {

    this.isLoaded = false;
    const self = this;

    this.Labels.filter((item, itemindex) => {

      if ((TabName === 'Added Labels' && item.itemName === TabName)) {

        self.Labels[itemindex].labels.filter((subitem, subindex) => {

          if (subitem.value === objitem.value) {

            checked = (checked === undefined || checked === false || checked === '') ? false : true;
            self.Labels[itemindex].labels[subindex].checked = !checked;

          }

        });

      }
      if ((TabName === 'Labels' && item.itemName === TabName)) {

        self.Labels[itemindex].labels.filter((subitem, subindex) => {

          if (subitem.value === objitem.value) {

            checked = (checked === undefined || checked === false || checked === '') ? false : true;
            self.Labels[itemindex].labels[subindex].checked = !checked;

          }

        });

      }
      self.isLoaded = true;

    });

  }

  /* Activity Event Section End */

  AddTags(event) {

    if (event.charCode === 13 && this.Tag.length > 0) {

      this.Tag = this.Tag.toUpperCase();
      this.Tags.push(this.Tag);
      this.Tag = '';

    }

  }

  AddTagsonClick() {

    if (this.Tag.length > 0) {

      this.Tag = this.Tag.toUpperCase();
      this.Tags.push(this.Tag);
      this.Tag = '';

    }

  }

  removeTag(tag) {

    const index = this.Tags.indexOf(tag);

    if (index > -1) {

      this.Tags.splice(index, 1);

    }

  }

}
