<app-loader-component [isLoaded]="isLoaded"></app-loader-component>

<div
   class="story-landing-tab-container related-content-tabs"
   #relatedContentElement
   [attr.data-component]="'ncx/stories/landing/tabs/related-content'">
   <nz-tabset
      [(nzSelectedIndex)]="tabSelectedIndex"
      nzType="card"
      class="">
      <nz-tab
         *ngFor="let tab of tabs; let selectedTabIndex = index"
         [nzTitle]="tab.title">
         <div>
            <div class="tab-content">
               <ng-container *ngIf="selectedTabIndex === 0">
                  <guest-booking-related-content
                     [storyId]="storyId"
                     [relatedContentHeight]="relatedContentHeight"
                     [relatedContentTop]="relatedContentTop"
                     (totalRecords)="assignGTTotalRecords($event)"></guest-booking-related-content>
               </ng-container>

               <ng-container *ngIf="selectedTabIndex === 1">
                  <crew-related-content
                     *ngIf="selectedTabIndex === 1"
                     [storyId]="storyId"
                     [relatedContentHeight]="relatedContentHeight"
                     [relatedContentTop]="relatedContentTop"
                     (totalRecords)="assignCrewTotalRecords($event)"></crew-related-content>
               </ng-container>

               <ng-container *ngIf="selectedTabIndex === 2">
                  <edit-related-content
                     *ngIf="selectedTabIndex === 2"
                     [storyId]="storyId"
                     [relatedContentHeight]="relatedContentHeight"
                     [relatedContentTop]="relatedContentTop"
                     (totalRecords)="assignEditTotalRecords($event)"></edit-related-content>
               </ng-container>

               <ng-container *ngIf="selectedTabIndex === 3">
                  <feedout-related-content
                     *ngIf="selectedTabIndex === 3"
                     [storyId]="storyId"
                     [relatedContentHeight]="relatedContentHeight"
                     [relatedContentTop]="relatedContentTop"
                     (totalRecords)="assignFeedOutTotalRecords($event)"></feedout-related-content>
               </ng-container>

               <ng-container *ngIf="selectedTabIndex === 4">
                  <fileIngest-related-content
                     *ngIf="selectedTabIndex === 4"
                     [storyId]="storyId"
                     [relatedContentHeight]="relatedContentHeight"
                     [relatedContentTop]="relatedContentTop"
                     (totalRecords)="assignFileIngestTotalRecords($event)"></fileIngest-related-content>
               </ng-container>
            </div>
         </div>
      </nz-tab>
   </nz-tabset>
</div>
