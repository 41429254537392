<app-loader-component
   [isLoaded]="isLoaded"
   loaderType="SECTION_LOADER"></app-loader-component>

<div class="add-to-angle">
   <p class="header">Add To Angle</p>

   <ng-container *ngIf="!isSelectAngle; else selectAngleTpl">
      <form [formGroup]="angleForm">
         <div
            *ngIf="isPostLoaded"
            class="search-box">
            <nz-input-group [nzSuffix]="suffixIconSearch">
               <input
                  type="text"
                  nz-input
                  placeholder="Search for Angle Name or ID"
                  formControlName="searchBox" />
            </nz-input-group>
            <ng-template #suffixIconSearch>
               <i
                  nz-icon
                  nzType="search"></i>
            </ng-template>
         </div>
      </form>

      <div
         *ngIf="angleForm.controls['searchBox'].value !== '' && isAnglesLoaded"
         class="angle-container">
         <ng-container *ngFor="let angleDetail of angleTitles; let first = first">
            <div [class.mt-16]="!first">
               <app-angle-details
                  [angleDetail]="angleDetail"
                  [isSelect]="true"
                  (selectAngleEvent)="selectAngle($event)"
                  (destroyAddToAngleModal)="destroyModal()"></app-angle-details>
            </div>
         </ng-container>
         <nz-empty
            *ngIf="angleTitles.length === 0"
            [nzNotFoundContent]="contentTpl">
            <ng-template #contentTpl>
               <span>No Angles Found</span>
            </ng-template>
         </nz-empty>
      </div>
   </ng-container>

   <ng-template #selectAngleTpl>
      <app-angle-details
         [angleDetail]="selectedAngle"
         [isDelete]="true"
         [isDisabled]="isDisabled"
         (deselectAngleEvent)="deselectAngle()"
         (destroyAddToAngleModal)="destroyModal()"></app-angle-details>
   </ng-template>

   <div class="footer">
      <button
         nz-button
         [disabled]="!isSelectAngle"
         nzType="primary"
         (click)="confirm()">
         Confirm
      </button>
   </div>
</div>
<ng-template #successTpl>
   <div class="success-tpl">
      <i
         nz-icon
         [nzType]="'check-circle'"
         [nzTheme]="'twotone'"
         [nzTwotoneColor]="'#52c41a'"></i>
      <div class="contents">
         <div class="header">
            <span>Successfully linked to Angle</span>
         </div>
         <div class="content">
            <span>"{{ postTitle }}" linked to</span>
            <span class="blue">
               <a
                  target="_blank"
                  href="/#/ncx/landing-angle/:{{ selectedAngle.angleId }}">
                  ({{ selectedAngle.angleId }}) {{ selectedAngle.angleTitle }}
               </a>
            </span>
         </div>
      </div>
   </div>
</ng-template>
