import { Component, Input } from '@angular/core';
import { environment } from '@environments/environment';
import { GoogleAnalyticsEventService } from '@services/google-analytics-events.service.service';
import { NavigationService } from '@services/navigation-service';

type NBCApp = 'guest-tracker' | 'ncx' | 'news-search' | 'producer-dashboard';

@Component({
  selector: 'app-bento-box',
  templateUrl: './bento-box.component.html',
  styleUrls: ['./bento-box.component.scss'],
})
export class BentoBoxComponent {

  // Links to show as options
  @Input() links: NBCApp[] = ['guest-tracker', 'ncx', 'news-search', 'producer-dashboard'];

  // Links to disable
  @Input() disabled: NBCApp[] = [];

  // Links to redirect to NewsConnect
  ncx_Url: string = '';

  // Links to redirect to Guest Tracker
  guest_tracker_Url: string = '';

  // Links to redirect to News Search
  news_search_Url: string = '';

  // Links to redirect to Producer Dashboard
  producer_dashboard_Url: string = '';


  constructor(
    private gaService: GoogleAnalyticsEventService, private navigationService: NavigationService
  ) { }

  ngOnInit() {

    const { environmentName } = environment;

    if (environmentName === 'stage') {

      this.ncx_Url = 'https://stg.newsconnect.nbcuni.com';

      this.guest_tracker_Url = 'https://stg.guesttracker.nbcuni.com';

      this.news_search_Url = 'https://stg.unifiedsearch.nbcuni.com';

      this.producer_dashboard_Url = 'https://stgui.producerdashboard.nbcuni.com';


    } else if (environmentName === 'qa') {

      this.ncx_Url = 'https://qa.newsconnect.nbcuni.com';

      this.guest_tracker_Url = 'https://qa.guesttracker.nbcuni.com';

      this.news_search_Url = 'https://qa.unifiedsearch.nbcuni.com';

      this.producer_dashboard_Url = 'https://qaui.producerdashboard.nbcuni.com';

    } else if (environmentName === 'dev') {

      this.ncx_Url = 'https://dev.newsconnect.nbcuni.com';

      this.guest_tracker_Url = 'https://dev.guesttracker.nbcuni.com';

      this.news_search_Url = 'https://dev.unifiedsearch.nbcuni.com';

      this.producer_dashboard_Url = 'https://devui.producerdashboard.nbcuni.com';


    } else if (environmentName === 'stagerts') {

      this.ncx_Url = 'https://stgui.newsconnect.nbcuni.com';

      this.guest_tracker_Url = 'https://stg.guesttracker.nbcuni.com';

      this.news_search_Url = 'https://stg.unifiedsearch.nbcuni.com';

      this.producer_dashboard_Url = 'https://stg.producerdashboard.nbcuni.com';

    } else if (environmentName === 'qarts') {

      this.ncx_Url = 'https://qaui.newsconnect.nbcuni.com';

      this.guest_tracker_Url = 'https://qa.guesttracker.nbcuni.com';

      this.news_search_Url = 'https://qa.unifiedsearch.nbcuni.com';

      this.producer_dashboard_Url = 'https://qa.producerdashboard.nbcuni.com';

    } else if (environmentName === 'devrts') {

      this.ncx_Url = 'https://devui.newsconnect.nbcuni.com';

      this.guest_tracker_Url = 'https://dev.guesttracker.nbcuni.com';

      this.news_search_Url = 'https://dev.unifiedsearch.nbcuni.com';

      this.producer_dashboard_Url = 'https://dev.producerdashboard.nbcuni.com';

    } else {

      this.ncx_Url = 'https://newsconnect.nbcuni.com';

      this.guest_tracker_Url = 'https://guesttracker.nbcuni.com';

      this.news_search_Url = 'https://unifiedsearch.nbcuni.com';

      this.producer_dashboard_Url = 'https://producerdashboard.nbcuni.com';

    }
  }


  /**
   * Open selected NBC app for the environment
   *
   */
  openNBCApp(url: string, key: NBCApp): boolean {

    if (this.navigationService.isIOSMobileApp.value) {
      return true;
    } else {
      const win = window.open();
      if (win) {
        win.opener = null;
        win.location.href = url;
        this.gaService.sendEvent('Bento Box', `Open ${key}`, url, 1);
        return false;
      }
    }
  }
}
