import { Location } from '@angular/common';
import { Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BreakpointService } from '@services/breakpoint.service';
import { GlobalSearchService } from '@services/global-search.service';
import { GoogleAnalyticsEventService } from '@services/google-analytics-events.service.service';
import { NzTabChangeEvent } from 'ng-zorro-antd/tabs';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-search-results',
  templateUrl: './search-results.component.html',
  styleUrls: ['./search-results.component.scss'],
})
export class SearchResultsComponent implements OnInit, OnDestroy {

  // State of the filter drawer
  isFilterDrawerCollapsed = true;

  // Default tab
  tabIndex = 1;

  tabOrder = ['STORIES', 'POSTS', 'ANGLES', 'GROUPS', 'PEOPLE'];

  // Search term entered in search box
  searchTerm: string = '';

  searchText: string = '';

  private subs = new SubSink();

  suggestions: string[] = [];

  suggestionSelected: boolean = false;

  private readonly localStorageKey = 'searchHistory';

  showPreview: boolean = false;

  searchBoxWidth: number = 0;

  // Search HTML input box
  @ViewChild('searchInputField') searchInputField!: ElementRef;

  @ViewChild('searchBox') searchBox!: ElementRef;


  @HostListener('document:click', ['$event'])
  clickout(event) {

    const isSearchSuggestionsContainer = !!event.target.closest('[data-id=search-suggestions]');

    const isSearchResultsInputContainer = !!event.target.closest('[data-id=search-input-box]');

    const isSuggestionDeleteIconContainer = !!event.target.closest('[data-id=suggestion-delete-icon]');

    // if search results is opened and clicking outside the results box or the input container, close everything
    if (!isSearchSuggestionsContainer && !isSearchResultsInputContainer && !isSuggestionDeleteIconContainer && this.suggestions.length > 0) {

      this.suggestions = [];

    }

  }


  @HostListener('window:resize', ['$event'])
  onResize() {

    this.searchBoxWidth = (this.searchBox?.nativeElement as HTMLElement)?.clientWidth;

    let searchBoxWithSuggestionsWidth = this.searchBoxWidth + 'px';

    document.documentElement.style.setProperty('--search-box-with-suggestions-width', searchBoxWithSuggestionsWidth);

  }


  constructor(
    private location: Location,
    private gaService: GoogleAnalyticsEventService,
    private searchService: GlobalSearchService,
    private activatedRoute: ActivatedRoute,
    private breakpointService: BreakpointService,
  ) {

    this.isFilterDrawerCollapsed = this.isMobile;

  }

  ngOnInit() {

    this.searchService.clearFilters();
    this.searchTerm = '';

    // When page loads, if there is a search term passed in the URL, populate the search service
    this.subs.sink = this.activatedRoute.queryParams.subscribe(param => {

      if (param.tab) {

        this.setInitTab(param.tab);

      }
      else if (param.searchString) {

        this.setInitSearchString(param.searchString);

      } else {
        this.searchService.searchTerm.next('');
      }

      console.log('Search Term:', this.searchTerm);

    });

    // If the search term changes by the Search Service, update the search text in the input box
    this.subs.sink = this.searchService.searchTerm.subscribe((searchText: string) => {

      this.gaService.trackGlobalSearch(searchText);
      this.searchTerm = searchText;

    });

    this.searchService.gloablSearchActive.next(false);
    // this.loadSuggestions();

  }

  /**
   * When changes are made in the metadata/sidebar filter drawer,
   * broadcast the change to all child components through the Search Service
   *
   */
  applyFilters() {

    this.searchService.filterChanged.next();

  }

  /**
   * Change Tab
   *
   */
  onChangeTab(event: NzTabChangeEvent) {

    this.tabIndex = event.index;

    this.updateURL();

  }

  /**
   * When search term is entered in input field
   *
   */
  setSearchTerm(event: any) {
    this.suggestionSelected = true;

    const text = (event.target.value || '').trim();

    this.saveSearchTerm(text);

    this.searchService.searchTerm.next(text);

    this.updateURL();

    this.suggestions = [];

    this.searchTerm == '';

    this.showPreview = false;

  }

  /**
   * Clear Search Term
   *
   */
  clearSearchTerm() {

    this.searchTerm = '';

    setTimeout(() => { this.onFocus(); }, 100);

    this.onResize();

    //this.searchService.clearFilters();

    //this.searchService.searchTerm.next('');

    // this.updateURL();

  }

  /**
   * If a tab is passed in the URL
   *
   */
  setInitTab(tab: string) {

    const tabs = this.tabOrder.map((key: string) => key.toLowerCase());

    if (tabs.includes(tab.toLowerCase())) {

      this.tabIndex = tabs.indexOf(tab);

    }

  }

  /**
   * If a search term is passed in the URL
   *
   */
  setInitSearchString(keyword: string) {

    this.searchService.searchTerm.next(keyword);
    this.searchTerm = keyword;
    this.suggestionSelected = true;

    this.searchText = '';
    this.searchText = keyword;

    this.saveSearchTerm(keyword);

  }

  /**
   * Toggle filter drawer
   *
   */
  toggleFilterDrawer(): void {

    this.isFilterDrawerCollapsed = !this.isFilterDrawerCollapsed;

  }

  /**
   * Close filter drawer
   *
   */
  closeFilterDrawer() {

    this.isFilterDrawerCollapsed = true;

  }

  /**
   * Update the URL with the tab and search string
   *
   */
  updateURL() {

    const hash = window.location.hash;

    const query = hash.split('?');

    let params;

    if (query && query.length) {

      params = new URLSearchParams(query[1]);

    } else {

      params = new URLSearchParams();

    }

    params.set('tab', this.tabOrder[this.tabIndex].toLowerCase());

    if (this.searchTerm) {

      params.set('searchString', this.searchTerm);

    } else {

      params.delete('searchString');

    }

    const queryString = decodeURIComponent(params.toString());

    this.location.replaceState(`/ncx/search-results?${queryString}`);

  }

  get isMobile(): boolean {

    return this.breakpointService.isMobile.value;

  }

  onInput(event: Event): void {
    const term = (event.target as HTMLInputElement).value;
    this.searchTerm = term;
    this.suggestions = [];
    this.suggestionSelected = false;
    //  setTimeout(() => { this.suggestions = []; }, 100);

    if (this.searchTerm.trim() === '') {
      this.onFocus();
    }


    this.searchText = term;

    this.showPreview = true;
  }

  onFocus(): void {
    this.loadSuggestions();
    this.searchInputField.nativeElement.focus();
    this.suggestionSelected = false;
  }

  selectSuggestion(suggestion: string): void {
    this.suggestionSelected = true;
    this.searchTerm = suggestion;
    this.suggestions = [];
    // Perform search logic here

    this.saveSearchTerm(this.searchTerm);

    this.searchService.searchTerm.next(this.searchTerm);

    this.updateURL();

    this.suggestions = [];

  }

  private saveSearchTerm(term: string): void {
    if (term.trim() === '') return;

    let searchHistory = JSON.parse(localStorage.getItem(this.localStorageKey) || '[]');

    const index = searchHistory.indexOf(term);
    if (index === -1) {
      // Term does not exist in the array, add it to the beginning
      searchHistory.unshift(term);
    } else {
      // Term exists in the array, remove it from its current position
      searchHistory.splice(index, 1);
      // Add the term to the beginning of the array
      searchHistory.unshift(term);
    }

    // Ensure the array length does not exceed 10 items
    if (searchHistory.length > 10) {
      searchHistory.pop();
    }

    localStorage.setItem(this.localStorageKey, JSON.stringify(searchHistory));

  }

  deleteSuggestionFromStorage(term: string): void {
    let searchHistory = JSON.parse(localStorage.getItem(this.localStorageKey) || '[]');
    const index = searchHistory.indexOf(term);
    if (index !== -1) {
      searchHistory.splice(index, 1);
      localStorage.setItem(this.localStorageKey, JSON.stringify(searchHistory));
    }
    this.searchTerm = '';
    this.loadSuggestions();
  }

  private loadSuggestions(): void {
    const searchHistory = JSON.parse(localStorage.getItem(this.localStorageKey) || '[]');
    if (this.searchTerm.trim() === '') {
      this.suggestions = searchHistory.slice(0, 5);
    }
  }

  ngOnDestroy() {
    this.searchService.gloablSearchActive.next(true);
  }

  seeAllResults() {
    this.showPreview = true;
  }

}
