import { AfterViewChecked, Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { BreakpointService } from '@services/breakpoint.service';
import { SubSink } from 'subsink';
import { TableColumns } from './related-content-utils';


@Component({
  selector: 'app-story-related-content',
  templateUrl: './story-related-content.component.html',
  styleUrls: ['../story-landing-tabs-common.scss', './story-related-content.component.scss'],
})
export class StoryRelatedContentComponent implements OnInit, OnDestroy, OnChanges, AfterViewChecked {

  isLoaded: boolean = false;

  relatedContentData: any = [];

  tabBodyHeight: string = '';

  tabBody: HTMLElement | any;

  relatedContentHeight: number = 0;

  relatedContentTop: number = 0;

  headerHeight: number = 0;

  createdSortOrder: any;

  preferenceColumns: { [key: string]: TableColumns } = {};

  columns: any;

  // Do we need to load additional items to engage the scrollbar?
  initLoadingMoreForScroll: boolean = false;

  // Story information
  @Input() storyId: number = 0;

  // Is this tab visible to the user?
  @Input() isVisible: boolean = false;

  // Height of the footer for viewport calculations
  @Input() footerHeight: number = 0;

  // Tell parent component when body is scrolling
  @Output() isScrolling: EventEmitter<void> = new EventEmitter<void>();

  segmentSelectedIndex: number = 0;

  tabSelectedIndex: number = 0;

  @ViewChild('closeIconTpl', { static: false }) closeIconTpl: any;

  private subs = new SubSink();

  tabs = [];

  tableParams: any;

  ecmDataFilters: any;

  options = [
    { label: 'Guest Bookings', value: '', disabled: false },
    { label: 'Field Crews', value: '', disabled: false },
    { label: 'Edits', value: '', disabled: false },
    { label: 'Feed Out', value: '', disabled: false },
    { label: 'File Ingest', value: '', disabled: false },
  ];

  @ViewChild('relatedContentElement', { static: false }) relatedContentElement: ElementRef | any;
  @ViewChild('tabBodyElement', { static: false }) tabBodyElement: ElementRef | any;
  @ViewChild('headerElement', { static: false }) headerElement: ElementRef | any;


  constructor(
    private breakpointService: BreakpointService
  ) { }

  ngOnInit() {

    this.segmentSelectedIndex = 0;

    this.tabs = [
      { name: 'Guest Bookings', title: 'Guest Bookings' },
      { name: 'Field Crews', title: 'Field Crews' },
      { name: 'Edits', title: 'Edits' },
      { name: 'Feed Out', title: 'Feed Out' },
      { name: 'File Ingest', title: 'File Ingest' }
    ];
  }

  ngOnChanges(changes: SimpleChanges) {

    // if (changes.storyId && changes.storyId.currentValue) {

    //   this.loadRelatedContent();

    // }

  }

  ngAfterViewInit() {
    this.isLoaded = true;
  }

  ngAfterViewChecked() {
    this.windowResizeEvent();
  }


  /**
   * Resize event to calculate the height of the inner body to enable scroll
   **/
  public windowResizeEvent() {

    const relatedContent = (this.relatedContentElement?.nativeElement as HTMLElement)?.getBoundingClientRect();
    this.relatedContentHeight = window.innerHeight - relatedContent?.top - this.footerHeight - 25.5;
    document.documentElement.style.setProperty('--relatedContentHeightInPx', this.relatedContentHeight + 'px');

    this.relatedContentTop = relatedContent.top;

    let searchInputHeight = this.tabSelectedIndex == 0 ? 0 : (32 + 16);

    let heightToBeRemoved = searchInputHeight + (16 * 3) + (24 * 2) + (40 * 2);
    let tableHeight = this.relatedContentHeight - heightToBeRemoved;
    document.documentElement.style.setProperty('--tableContentHeightInPx', tableHeight + 'px');

    let noDataTableHeight = this.relatedContentHeight - heightToBeRemoved + 16 + 32;
    document.documentElement.style.setProperty('--noDataTableHeightInPx', noDataTableHeight + 'px');
  }

  ngOnDestroy() {

    this.subs.unsubscribe();

  }



  assignGTTotalRecords(total: number) {
    this.tabs[0].title = 'Guest Bookings (' + total + ')';
  }

  assignCrewTotalRecords(total: number) {
    this.tabs[1].title = 'Field Crews (' + total + ')';
  }

  assignEditTotalRecords(total: number) {
    this.tabs[2].title = 'Edits (' + total + ')';
  }

  assignFeedOutTotalRecords(total: number) {
    this.tabs[3].title = 'Feed Out (' + total + ')';
  }

  assignFileIngestTotalRecords(total: number) {
    this.tabs[4].title = 'File Ingest (' + total + ')';
  }


  get isMobile(): boolean {

    return this.breakpointService.isMobile.value;

  }
}

