import { Component, OnDestroy, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { environment } from '@environments/environment';
import { Filters, Option } from '@models/ncx/filters';
import { FollowStoryUpdateEvent } from '@models/ncx/followers';
import { StoriesLanding } from '@models/ncx/stories-landing';
import { Stories } from '@models/ncx/story';
import { PERMISSIONS, Profile, UserSettings } from '@models/users';
import { StoryPostsComponent } from '@ncx/stories/landing/tabs/posts/story-posts.component';
import { BannerSessionResizeObserverService } from '@services/banner-session-resize-observer.service';
import { BreakpointService } from '@services/breakpoint.service';
import { ClipboardService } from '@services/clipboard.service';
import { CommonService } from '@services/common-service';
import { GoogleAnalyticsEventService } from '@services/google-analytics-events.service.service';
import { NavigationService } from '@services/navigation-service';
import { PermissionsService } from '@services/profile/permissions.service';
import { ToastService } from '@services/toastService/toastMessage.service';
import { UtilityService } from '@services/utility.service';
import { WebSocketService } from '@services/websocket.service';
import { Common } from '@utilities/common';
import { NzOptionSelectionChange } from 'ng-zorro-antd/auto-complete';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-new-story-landing',
  templateUrl: './story-landing.component.html',
  styleUrls: ['./story-landing.component.scss']
})
export class NewStoriesLandingComponent implements OnDestroy {

  // Loader for Story Details
  isAuthorListLoaded: boolean = true;

  // State of Filter drawer/sider for both mobile and desktop
  isFilterDrawerOpen: boolean = false;

  // Landing page information
  storyLandingDetails = null as StoriesLanding;

  // Header state
  isSticky = false;

  // For Share Story & Infocenter
  storyId: number = 0;

  // Standards in the url
  standards: boolean = false;

  // UserId
  userId: number = 0;

  // Posts=0 or Angles=1
  selectedTab: number = 0;

  // InfoCenter Modal
  infoCenterVisible: boolean = false;

  // All Post Filters & Search
  postFilters!: UntypedFormGroup;

  // All Angle Filters & Search
  angleFilters!: UntypedFormGroup;

  // Filter Options
  filterOptions: Filters = {} as Filters;

  //Post Filter Order
  postFilterOrder = [
    {
      'label': 'Newest First',
      'value': 'descending'
    },
    {
      'label': 'Oldest First',
      'value': 'ascending'
    }
  ];

  //Angle Filter Order
  angleFilterOrder = [
    {
      'label': 'Newest First',
      'value': 'descending'
    },
    {
      'label': 'Oldest First',
      'value': 'ascending'
    }
  ];

  //Angle Privacy Order
  anglePrivacyOrder = [
    {
      'label': 'All',
      'value': 'all'
    },
    {
      'label': 'Public',
      'value': 'public'
    },
    {
      'label': 'Private',
      'value': 'private'
    }
  ];

  numberofWsUpdates: number = 0;

  userSettingUrl: string = environment.getSettingsAPIURL + localStorage.getItem('userId') + '/settings';

  userSettings: UserSettings;

  //default Sort
  defaultSort = 'descending';

  defaultPrivacy = 'all';

  // List of Authors to choose from in Angles filter
  authorName: string = '';              // text entered in search bar

  authorSearchSubject = new Subject();  // listens for changes to the search bar for debouncing

  authorList: Profile[] = [];           // list of matching profiles from the API

  footerHeight: number = 64;

  readonly Common = Common;

  subscriptionsWs: Subscription = new Subscription();

  breakPointSubscription: Subscription = new Subscription();

  routerSubscription: Subscription = new Subscription();

  authorSearchSubscription: Subscription = new Subscription();

  @ViewChild(StoryPostsComponent) storyPost: StoryPostsComponent;

  constructor(
    private router: Router,
    private navigationService: NavigationService,
    private activatedRoute: ActivatedRoute,
    private utilityService: UtilityService,
    private wS: WebSocketService,
    private fb: UntypedFormBuilder,
    private cService: CommonService,
    private toastService: ToastService,
    private bannerSessionResizeObserverService: BannerSessionResizeObserverService,
    private breakpointService: BreakpointService,
    private permissionsService: PermissionsService,
    private clipboardService: ClipboardService,
    private gaService: GoogleAnalyticsEventService,
  ) {

    this.postFilters = this.fb.group({
      type: 'All',          // All | Elements | Standard
      search: '',           // Posts Search bar
      labels: [],           // R&C/Legal labels
      date: [],             // Date Range picker
      sort: 'descending'
    });

    this.angleFilters = this.fb.group({
      authorSSO: 0,         // SSO of Author name
      search: '',           // Angles Search bar
      sort: 'descending',
      privacy: 'all'
    });

    this.bannerSessionResizeObserverService.setBannerSessionHeight();

    this.userId = Number(localStorage.getItem('userId'));

    this.subscriptionsWs = this.wS.doSocketData$.subscribe((data) => {

      console.log('Story Id in the web socket update before if condition check ' + data.story?.storyId);
      if (data.pageCode === "STORY_" + this.storyId && data.story?.storyId == this.storyId) {

        console.log('Story Id in the web socket update ' + data.story?.storyId);
        console.log('Story Id of the page at the time of web socket update ' + this.storyId);
        console.log('web socket update count ' + ++this.numberofWsUpdates);
        console.log('Web socket notification received. Calling StoryDetails event in story landing');
        // this.setStoryDetails(data.story); //NRT-14629
        this.loadStory();

        // This timeout is necessary for search api to load complete results: NRT 13370
        setTimeout(() => {

          this.storyPost.loadPosts();

        }, 1000);


      }

    });

    // Get the story Id from the URL and load required APIs
    this.routerSubscription = this.activatedRoute.params.subscribe((params: Params) => {

      this.storyId = params.storyId.substr(1);

      console.log('Story Id of the page at the time of page load ' + this.storyId);

      if (this.storyId && !isNaN(this.storyId)) {

        this.loadStory();
        this.loadFilterOptions();

        // this.wS.connect('STORY_' + this.storyId);

      }
      const secondParamsInURL = params.standards?.substr(0);

      if (secondParamsInURL === 'standards') {

        this.standards = true;
        this.selectLabel('STANDARDS', { checked: true, disable: true });

      }

    });

    // Listen for text entered in the Author search box of the Angles filter
    this.authorSearchSubscription = this.authorSearchSubject.pipe(debounceTime(500)).subscribe(() => {

      this.loadAuthorList(this.authorName);

    });

    // Drawer is automatically opened on desktop view
    this.isFilterDrawerOpen = !this.breakpointService.isMobile.value;

    // Automatically close drawer when moving to mobile view
    this.breakPointSubscription = this.breakpointService.isMobile.subscribe((isMobile) => {

      if (isMobile && this.isFilterDrawerOpen) {

        this.isFilterDrawerOpen = false;

      }

    });

  }

  getEmail() {

    const domain = this.utilityService.getMailURL();

    return `mailto:story+${this.storyId.toString().trim()}@mail.${domain.trim()}`;

  }

  /**
   * Get information about the Story
   *
   */
  loadStory() {

    this.cService.serviceRequestCommon('get', environment.getStoriesAPI, `/${this.storyId}?isPosts=true`).subscribe((res: any) => {

      const story: Stories = res as Stories;

      this.setStoryDetails(story);

    }, (error: any) => {

      if (error === 'STORY-003') {

        this.toastService.createMessage('error', 'You Do Not Have Access to the Story');

        setTimeout(() => {

          this.router.navigate(['ncx/stories-dashboard']);

        }, 500);

      } else {

        this.toastService.createMessage('error', error);

      }

    });

  }

  /**
   * Set/Update Story Landing page information including access
   *
   */
  setStoryDetails(story: Stories) {

    const isDeleted = story?.isDeleted;

    const isPrivate = story?.storyAccess === 'private' &&
      !this.permissionsService.hasPermissionTo(PERMISSIONS.ACCESS_PRIVATE_STORY, { createUser: story?.createUser });

    if (isDeleted) {

      this.toastService.createMessage('error', `Requested Story doesn't exist. Redirecting to stories dashboard.`);

    }

    if (isPrivate) {

      this.toastService.createMessage('error', 'You do not have access to this story. Redirecting to Dashboard.');

    }

    if (isPrivate || isDeleted) {

      this.router.navigate(['ncx/stories-dashboard']);
      return;

    }

    this.storyLandingDetails = JSON.parse(JSON.stringify(story));

    // this.storyLandingDetails.autoGeneratedStoryId = 'HardCoded - SA47FL0001';

    this.navigationService.overridePageTitle(story.storyTitle);

  }

  /**
   * Get all Filter options for drawer display
   *
   */
  loadFilterOptions() {

    this.cService.serviceRequestCommon('get', environment.getPostFiltersURL).subscribe((res: any) => {

      this.filterOptions = res as Filters;

    }, (error: any) => {

      console.error('Get Filter List', error);

      this.toastService.createMessage('error', 'Error while loading Filter options. Please try again.');

    });

  }

  /**
   * Listens for keystrokes from Search Author in Angles filters
   *
   */
  searchAuthors(authorSearchText: string, event: KeyboardEvent) {

    if (event.key !== 'ArrowUp' && event.key !== 'ArrowDown') {

      if (authorSearchText?.trim() != '') {

        this.authorName = authorSearchText.trim();

        if (this.authorName) {

          this.authorSearchSubject.next('');

        } else {

          this.angleFilters.patchValue({ authorSSO: 0 });

        }

      }

    }

  }

  /**
   * When Author is selected in Angles Filter
   *
   */
  selectedAuthor(event: NzOptionSelectionChange, selectedAuthor: any) {

    if (event.isUserInput) {

      const sso = selectedAuthor.ssoId;

      this.angleFilters.patchValue({ authorSSO: sso });

    }

  }

  /**
   * Load list of Authors to choose from in auto-complete
   *
   */
  loadAuthorList(text: string) {

    this.isAuthorListLoaded = false;

    this.authorList = [];

    this.cService.serviceRequestCommon('get', environment.getUsersOnDutyURL, '?name=' + text + '&isActiveUsers=false').subscribe((res: any) => {

      const authors: Profile[] = res as Profile[];

      this.authorList = authors;

    }, (err) => {

      this.toastService.createMessage('error', 'Unable to load list of Authors. Please try again.');

      console.error('fetchAuthorList:', err);

    }).add(() => {

      this.isAuthorListLoaded = true;

    });

  }

  /**
   * Clear all Label checked/disabled status for Posts
   *
   */
  resetLabels() {

    const labels: Option[] = this.filterOptions.markAsType.concat(this.filterOptions.statusType);

    labels.forEach(obj => {

      obj.checked = false;
      obj.disabled = false;

    });

    this.postFilters.patchValue({ labels: [] });

  }

  /**
   * Reset all filters for Posts
   *
   */
  resetPostFilters() {

    this.resetLabels();

    if (this.postFilters.get('type').value === 'Standards') {

      this.selectLabel('STANDARDS', { checked: true, disable: true });

    }

    this.postFilters.patchValue({
      date: [],
      sort: this.defaultSort
    });

  }

  /**
   * Reset ALL filters for Angles
   *
   */
  resetAngleFilters() {

    this.authorName = '';

    this.angleFilters.patchValue({
      authorSSO: 0,
      sort: this.defaultSort,
      privacy: this.defaultPrivacy
    });

    this.authorList = [];

  }

  /**
   * Clear ONLY the Author Search for Angles
   *
   */
  resetAuthorSearch() {

    this.authorName = '';

    this.angleFilters.patchValue({
      authorSSO: 0
    });

    this.authorList = [];

  }

  /**
   * Select Sort filter for Posts
   *
   */
  selectSortFilter(sort: string) {

    this.postFilters.patchValue({ sort });

  }

  /**
   * Select Date filter for Posts
   *
   */
  selectDateFilter(dates: Date[]) {

    this.postFilters.patchValue({ date: dates });

  }

  /**
   * Change status of Editorial or Legal labels and set the
   * selected label array for the Posts Filter form
   *
   */
  selectLabel(label: string, { checked, disable }: { checked: boolean, disable?: boolean }) {
    this.filterOptions.markAsType = this.filterOptions.markAsType ? this.filterOptions.markAsType : [];
    this.filterOptions.statusType = this.filterOptions.statusType ? this.filterOptions.statusType : [];
    const labels: Option[] = this.filterOptions.markAsType.concat(this.filterOptions.statusType);

    const index = labels.findIndex((obj) => obj.value === label);

    if (index !== -1) {

      if (typeof checked !== 'undefined') {

        labels[index].checked = checked;

      }
      if (typeof disable !== 'undefined') {

        labels[index].disabled = disable;

      }

    }

    const selected = labels.filter((obj: Option) => obj.checked).map((obj: Option) => obj.value);

    this.postFilters.patchValue({ labels: selected });

  }

  /**
   * Open/Close filter drawer
   *
   */
  toggleFilterDrawer() {

    this.isFilterDrawerOpen = !this.isFilterDrawerOpen;

  }

  /**
   * Back button click in Header
   *
   */
  goBack() {

    const url = this.navigationService.previousPath.value || '/ncx/stories-dashboard';

    this.router.navigateByUrl(url);

  }

  /**
   * When Follow/Unfollow status changes, update the header information
   *
   */
  updateFollowStatus(status: FollowStoryUpdateEvent) {

    this.storyLandingDetails.storyFollowerCount = status.data.storyFollowerCount;
    this.storyLandingDetails.isUserFollowing = status.data.followerUserIDs.includes(this.userId);
    this.gaService.sendEvent('Follow Story Click', 'Story Landing Page', '' + this.storyId, 1);

  }

  /**
   * View followers page
   *
   */
  viewFollowers() {

    this.router.navigate(['ncx/following/:' + this.storyId]);

  }

  /**
   * View Story Details page
   *
   */
  viewStoryDetails() {

    this.router.navigate(['ncx/view-story/:' + this.storyId]);

  }

  /**
   * Show InfoCenter Modal
   *
   */
  toggleInfoCenter() {

    this.infoCenterVisible = !this.infoCenterVisible;

  }

  /**
   * Go to Add Post page
   *
   */
  addPost() {
    this.gaService.sendEvent('Add Post Click', 'Story Landing Page', '' + this.storyId, 1);

    if (this.selectedTab === 0) {

      this.router.navigateByUrl(`/ncx/post?storyId=${this.storyId}`);

    }

    if (this.selectedTab === 1) {

      this.router.navigateByUrl(`/ncx/angle?storyId=${this.storyId}`);

    }

  }

  /**
   * Trigger e-mail client to add Post
   *
   */
  addPostViaEmail() {

    const domain = this.utilityService.getMailURL();

    Common.sendEmail({ to: [`story+${this.storyId.toString().trim()}@mail.${domain.trim()}`] });
    this.gaService.sendEvent('Add Post via Email Click', 'Story Landing Page', `story+${this.storyId.toString().trim()}@mail.${domain.trim()}`, 1);

  }

  /**
   * When the body of either tab is scrolled, change the header style
   *
   */
  onScrollContainer() {

    const container = document.getElementById('storyContainer');

    const header = document.getElementById('storyHeader');

    this.isSticky = container && header && container.scrollTop > header.clientHeight + 20;

  }

  /**
   * Scroll page to top
   *
   */
  scrollTop() {

    const container = document.getElementById('storyContainer');

    if (container) {

      (container as HTMLElement).scrollTo({ top: 0, behavior: 'smooth' });

    }

  }

  tabMenuChanges(_tabIndex: number) {

    this.isSticky = false;
    this.postFilters.controls['type'].setValue('All');

  }

  get canAddPostViaEmail(): boolean {

    return this.permissionsService.hasPermissionTo(PERMISSIONS.ADD_POST_VIA_EMAIL);

  }

  get canCreatePost(): boolean {

    return this.permissionsService.hasPermissionTo(PERMISSIONS.CREATE_POST);

  }

  get isMobile(): boolean {

    return this.breakpointService.isMobile.value;

  }

  copyStoryIdInClipboard($event) {

    this.clipboardService.copyToClipboard($event);

    this.toastService.createMessage('success', 'ID copied to clipboard');

  }

  //to unsubscribe all the subscriptions
  ngOnDestroy() {

    this.subscriptionsWs.unsubscribe();
    this.breakPointSubscription.unsubscribe();
    this.routerSubscription.unsubscribe();
    this.authorSearchSubscription.unsubscribe();

  }

}
