import { User } from '../users';

export interface Stories {
  storyId: number;
  isSlackIntegration: boolean;
  storyTitle: string;
  storyTopic: string;
  subject: { entity: string, entityType: string, entityCode: string };
  storyContent: string;
  storyPlaintContent: string;
  standardGuidance: string;
  standardGuidancePlainContent: string;
  isUserFollowing: boolean;
  isGenerateTags: boolean;
  isDeleted: boolean;
  isGroupPost: boolean;
  ranking: number;
  repliesCount: number;
  doNotSendEmail: boolean;
  storySlug: {
    who: string;
    what: string;
    where: string;
    when: string
  };
  storyState: string;
  isStoryPublished: boolean;
  storyAccess: string;
  storyContentTags: string[];
  storyPosts: StoriesPost[];
  storyFollowerIds: number[];
  storyFollower: any[];
  storyUserMentions: number[];
  storyFollowerCount: number;
  storyPostCount: number;
  storyAttachmentCount: number;
  createDateTime: string;
  updateDateTime: string;
  createUserId: number;
  updateUserId: number;
  pageStartCount: number;
  pageLength: number;
  createUser: User;
  updateUser: User;
  topics: { [key: number]: string };
  slackChannelName: string[];
  isSlackBlockedSubscription: boolean;
  autoGeneratedStoryId: string;
  storyTopics: any[];
  topicDetails: any[];
  storyTopicIds: string[];
  postContentTags: string[];
}

export class StoryClass {

  storyId: 0;

  storyTitle: '';

  storyContent: '';

  storySlug: {
    who: '';
    what: '';
    where: '';
    when: ''
  };

  storyState: '';

  isStoryPublished: false;

  storyAccess: '';

  storyContentTags: [
    ''
  ];

  storyPosts: [
    {
      postId: 0;
      postType: '';
      postTitle: '';
      postContent: '';
      postContentTags: [
        ''
      ];
      isPostPublished: false;
      postStatus: '';
      postMarkedAs: [
        ''
      ];
      editorialStatus: [
        ''
      ];
      postLegal: [
        ''
      ];
      relatedStories: [
        {
          storyId: null;
          isPrimary: false;
          isPinnedOnTop: false
        }
      ];
      postAttachments: [
        {
          attachmentId: null;
          attachmentName: '';
          attachmentMIMEType: '';
          attachmentPath: '';
          attachmentBinaries: '';
          attachmentPreview: '';
          attachmentPreviewBinaries: '';
          isDeleted: false;
          createDateTime: '';
          updateDateTime: '';
          createUserId: null;
          updateUserId: null;
          status: '';
          size: null;
        }
      ];
      attachmentDetails: {
        linkToSource: '';
        clearanceStatus: '';
        captureDate: '';
        isMandatoryCredit: false;
        creditName: ''
      };
      postReplies: [
        {
          replyId: null;
          postGroupId: null;
          replyContent: '';
          replyUserMentions: [
            null
          ];
          createUserId: null;
          updateUserId: null;
          createDateTime: '';
          updateDateTime: '';
          isDeleted: false
        }
      ];
      postVersionnull: null;
      postVersionState: '';
      postVersionGroupId: null;
      createDateTime: '';
      updateDateTime: '';
      createUserId: null;
      updateUserId: null;
      isDeleted: false
    }
  ];

  storyFollower: [
    null
  ];

  storyUserMentions: [
    null
  ];

  storyFollowerCount: null;

  storyPostCount: null;

  storyAttachmentCount: null;

  createDateTime: '';

  updateDateTime: '';

  createUserId: null;

  updateUserId: null;

  isDeleted: false;

  pageStartCount: null;

  pageLength: null;

  isSlackBlockedSubscription: false;

  storyTitleId = '';

}

export interface StoriesPost {
  postType: string;
  snippet: string;
  postId: number;
  storyId: number;
  sentToPam: boolean;
  postTitle: string;
  postContent: string;
  postPlainContent: string;
  postContentTags: string[];
  isPostPublished: boolean;
  postStatus: string;
  postMarkedAs: string[];
  editorialStatus: string[];
  postLegal: string[];
  isPinnedOnTop: boolean;
  relatedStories: StoryRelated[];
  reportableApprover: StoryReportableApprover;
  standardsGuidance: string;
  limitedLicenseApprover: string;
  postAttachments: StoryPostAttachment[];
  attachmentDetails: {
    linkToSource: string;
    clearanceStatus: string;
    captureDate: string;
    isMandatoryCredit: boolean;
    creditName: string
  };
  postReplies: StoryPostReplies[];
  repliesCount: number;
  postVersionNumber: number;
  postVersionState: string;
  postVersionGroupId: number;
  livePostId: number;
  createDateTime: string;
  updateDateTime: string;
  createUser: User;
  updateUser: User;
  isDeleted: boolean;
  locked: string;
  lockedByUser: string;
  plumeGuid: string;
}

export interface StoryRelated {
  storyId: number;
  isPrimary: boolean;
  isPinnedOnTop: boolean;
}

export interface StoryReportableApprover {
  isOfficial: string;
  seniorApprovalName: string;
  additionalNotes: string;
}

export interface StoryPostAttachment {
  attachmentId: number;
  attachmentName: string;
  attachmentMIMEType: string;
  attachmentPath: string;
  attachmentBinaries: string;
  attachmentPreview: string;
  attachmentPreviewBinaries: string;
  isDeleted: boolean;
  createDateTime: string;
  updateDateTime: string;
  createUserId: number;
  updateUserId: number;
  status: string;
  size: number;
}

export interface StoryPostReplies {
  replyId: number;
  postGroupId: number;
  replyContent: string;
  replyUserMentions: [
    number
  ];
  createUserId: number;
  updateUserId: number;
  createDateTime: string;
  updateDateTime: string;
  isDeleted: boolean;
}

export enum StoryMode { Private = 'private', Public = 'public' }
