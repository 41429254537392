import { AfterViewChecked, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { environment } from '@environments/environment';
import { BreakpointService } from '@services/breakpoint.service';
import { CommonService } from '@services/common-service';
import { GoogleAnalyticsEventService } from '@services/google-analytics-events.service.service';
import { NavigationService } from '@services/navigation-service';
import { ToastService } from '@services/toastService/toastMessage.service';
import { debounceTime, Subject, takeUntil } from 'rxjs';
import { SubSink } from 'subsink';
import { manipulateFeedOutData } from '../related-content';
import { FEEDOUT_AIR_DATE_FILTERS, FEEDOUT_API_FILTERS, FEEDOUT_COLUMNS, FEEDOUT_DEFAULT_TABLE_PARAMS, FEEDOUT_REQUEST_STATUS_DETAILS, FEEDOUT_STATUS_FILTERS, TableColumns } from '../related-content-utils';


type NBCApp = 'guest-tracker' | 'ncx' | 'news-search' | 'producer-dashboard';

@Component({
  selector: 'feedout-related-content',
  templateUrl: './feedout-related-content.component.html',
  styleUrls: ['../story-related-content.component.scss', './feedout-related-content.component.scss'],
})
export class FeedoutRelatedContentComponent implements OnInit, OnDestroy, OnChanges, AfterViewChecked {

  // Story information
  @Input() storyId: number = 0;

  @Input() relatedContentHeight: number = 0;

  @Input() relatedContentTop: number = 0;

  @Output() totalRecords: EventEmitter<number> = new EventEmitter<number>();

  isLoaded: boolean = true;

  relatedContentData: any = [];

  headerHeight: number = 0;

  tableHeight: number = 0;

  heightToBeRemoved: number = 0;

  preferenceColumns: { [key: string]: TableColumns } = {};

  columns: any;

  private subs = new SubSink();

  submittedDateSortOrder: string = null;
  apiQueryFilters: any;
  tableParams: any;
  statusFilters: any;
  requestStatusDetails: any;
  submittedDateFilters: any;

  idSearchVisible: boolean = false;
  idSearchText: string = '';

  showOrProjectSearchVisible: boolean = false;
  showOrProjectSearchText: string = '';
  priorShowOrProjectSearchText: string = '';

  requesterSearchVisible: boolean = false;
  requesterSearchText: string = '';
  priorRequesterSearchText: string = '';

  destinationSearchVisible: boolean = false;
  destinationSearchText: string = '';
  priorDestinationSearchText: string = '';

  mediaIdSearchVisible: boolean = false;
  mediaIdSearchText: string = '';
  priorMediaIdSearchText: string = '';

  searchText: Subject<string> = new Subject();

  private destroy$ = new Subject();

  constructor(
    private breakpointService: BreakpointService,
    private cService: CommonService,
    private toastService: ToastService,
    private gaService: GoogleAnalyticsEventService,
    private navigationService: NavigationService
  ) { }

  ngOnInit() {

    this.searchText.pipe(debounceTime(500), takeUntil(this.destroy$)).subscribe(() => {

      this.searchId();
    });

    this.submittedDateFilters = JSON.parse(JSON.stringify(FEEDOUT_AIR_DATE_FILTERS));

    this.apiQueryFilters = JSON.parse(JSON.stringify(FEEDOUT_API_FILTERS));

    this.tableParams = JSON.parse(JSON.stringify(FEEDOUT_DEFAULT_TABLE_PARAMS));

    this.statusFilters = JSON.parse(JSON.stringify(FEEDOUT_STATUS_FILTERS));

    this.requestStatusDetails = JSON.parse(JSON.stringify(FEEDOUT_REQUEST_STATUS_DETAILS));

    this.loadColumns();

    if (this.storyId)
      this.loadRelatedContent(true);
  }

  ngOnChanges(changes: SimpleChanges) {

    if (changes.storyId && changes.storyId.currentValue) {

      this.storyId = changes.storyId.currentValue;

    }

  }

  ngAfterViewChecked() {

    // this.windowResizeEvent();

  }


  /**
   * Resize event to calculate the height of the inner body to enable scroll
   **/
  public windowResizeEvent() {

    // this.heightToBeRemoved = (16 * 3) + (24 * 2) + (40 * 2);

    // this.tableHeight = this.relatedContentHeight - this.heightToBeRemoved;

  }

  ngOnDestroy() {

    this.subs.unsubscribe();

    this.destroy$.complete();

  }

  /**
   * Make API call to get the related content for the story
   *
   */
  loadRelatedContent(emitTotalRecords: boolean = false) {

    this.isLoaded = false;

    this.apiQueryFilters.storyId = this.storyId;// 1733260492448;//1724947933414;

    let apiResponse = this.cService.serviceRequestCommon('post', environment.feedoutRequestAPI, '', this.apiQueryFilters);

    apiResponse.subscribe((res: any) => {

      let results = res?.results || [];

      if (Array.isArray(results)) {

        manipulateFeedOutData(res?.results);

        this.relatedContentData = [...results];

        // console.log('related Content', { relatedContent: this.data, response });

        this.tableParams.total = res.total || 0;

        if (emitTotalRecords) {
          this.totalRecords.emit(this.tableParams.total);
        }

      }

    }, (error: any) => {

      console.error('Load Related Content', error);

      this.toastService.createMessage('error', 'Error while loading related content. Please try again.');

    }).add(() => {

      this.isLoaded = true;

    });

  }

  get isMobile(): boolean {

    return this.breakpointService.isMobile.value;

  }


  /**
    * Changing pages
    * @param page {number}
    *
    */
  onChangePage(page: number) {

    this.tableParams.page = page;
    this.apiQueryFilters.start = ((page - 1) * this.apiQueryFilters.count) + 1;

    this.loadRelatedContent();

  }

  /**
   * Changing number of items to display per page
   * @param limit {number}
   *
   */
  onChangePageSize(limit: number) {

    this.tableParams.limit = limit;
    this.apiQueryFilters.count = limit;

    // If Page size changed then we need to reset page index as 1 as well start also
    const page = 1;
    this.tableParams.page = page;
    this.apiQueryFilters.start = ((page - 1) * this.apiQueryFilters.count) + 1;

    this.loadRelatedContent();
  }

  navigationTo(id: string) {

  }



  loadColumns() {

    this.preferenceColumns = {};

    this.columns = JSON.parse(JSON.stringify(FEEDOUT_COLUMNS));;

    this.columns.forEach((column: TableColumns) => {
      this.preferenceColumns[column.value] = column;
    });

  }

  typeFilterEvent(event: any) {

    this.resetTableParams();

    if (event?.length == 0)
      this.apiQueryFilters.Type = [];
    else
      this.apiQueryFilters.Type = event;

    this.loadRelatedContent();

  }

  statusFilterEvent(event: any) {

    this.resetTableParams();

    if (event?.length == 0)
      this.apiQueryFilters.status = ["NEW", "REVISED", "IN PROGRESS", "COMPLETED"];
    else
      this.apiQueryFilters.status = event;

    this.loadRelatedContent();

  }

  submittedDateFilterEvent(event: any) {

    this.resetTableParams();

    if (event?.length == 0)
      this.apiQueryFilters.created = [];
    else
      this.apiQueryFilters.created = event;

    this.loadRelatedContent();

  }

  submittedDateSortEvent(event: any) {

    if (event === null && this.apiQueryFilters.sortField !== 'created') return;
    this.resetTableParams();

    if (event == null) {
      this.apiQueryFilters.sortField = 'created';
      this.apiQueryFilters.sortOrder = 'descending';
    } else {
      this.apiQueryFilters.sortField = 'created';
      this.apiQueryFilters.sortOrder = (event == 'ascend') ? 'ascending' : 'descending';
    }

    this.submittedDateSortOrder = event;

    this.loadRelatedContent();
  }

  resetTableParams() {

    const currentTableParams = JSON.parse(JSON.stringify(this.tableParams));

    let defaultTableParams = JSON.parse(JSON.stringify(FEEDOUT_DEFAULT_TABLE_PARAMS));

    defaultTableParams.limit = currentTableParams.limit;

    this.tableParams = JSON.parse(JSON.stringify(defaultTableParams));

    this.apiQueryFilters.start = 1;

  }

  /** 
   * Search for id
  */
  search() {
    this.searchText.next(this.idSearchText);
  }

  searchId(): void {
    this.resetTableParams();
    this.idSearchVisible = false;
    if (this.idSearchText.length === 0) {
      this.apiQueryFilters.filter.id = '*';
    } else {
      this.apiQueryFilters.filter.id = this.idSearchText + '*';
    }
    this.loadRelatedContent();
  }

  resetIdSearch(): void {
    this.idSearchVisible = false;
    this.idSearchText = '';
    this.apiQueryFilters.filter.id = '*';
    this.loadRelatedContent();
  }

  /** 
  * Search for a show
 */
  searchShowOrProject(): void {
    this.resetTableParams();
    this.showOrProjectSearchVisible = false;
    if (this.showOrProjectSearchText.length === 0) {
      this.apiQueryFilters.showUnit = [];
    } else {
      this.apiQueryFilters.showUnit = [this.showOrProjectSearchText + '*'];
    }
    this.priorShowOrProjectSearchText = this.showOrProjectSearchText;
    this.loadRelatedContent();
  }

  resetShowOrProjectSearch(): void {
    this.showOrProjectSearchVisible = false;
    this.showOrProjectSearchText = '';
    this.apiQueryFilters.showUnit = [];
    this.priorShowOrProjectSearchText = '';
    this.loadRelatedContent();
  }

  ShowOrProjectVisibleChange(event: boolean): void {
    if (!event && this.showOrProjectSearchText !== this.priorShowOrProjectSearchText) {
      this.searchShowOrProject();
    }
  }

  /**
   * Search for a Requester
   */
  searchRequester(): void {
    this.resetTableParams();
    this.requesterSearchVisible = false;
    if (this.requesterSearchText.length === 0) {
      this.apiQueryFilters.requester = '';
    } else {
      this.apiQueryFilters.requester = this.requesterSearchText + '*';
    }
    this.priorRequesterSearchText = this.requesterSearchText;
    this.loadRelatedContent();
  }

  resetRequesterSearch(): void {
    this.requesterSearchVisible = false;
    this.requesterSearchText = '';
    this.apiQueryFilters.requester = '';
    this.priorRequesterSearchText = '';
    this.loadRelatedContent();
  }

  requesterVisibleChange(event: boolean): void {
    if (!event && this.requesterSearchText !== this.priorRequesterSearchText) {
      this.searchRequester();
    }
  }

  /**
 * Search for a destination
 */
  searchDestination(): void {
    this.resetTableParams();
    this.destinationSearchVisible = false;
    if (this.destinationSearchText.length === 0) {
      delete this.apiQueryFilters.filter.destinations;
    } else {
      this.apiQueryFilters.filter.destinations = this.destinationSearchText + '*';
    }
    this.priorDestinationSearchText = this.destinationSearchText;
    this.loadRelatedContent();
  }

  resetDestinationSearch(): void {
    this.destinationSearchVisible = false;
    this.destinationSearchText = '';
    delete this.apiQueryFilters.filter.destinations;
    this.priorDestinationSearchText = '';
    this.loadRelatedContent();
  }

  destinationVisibleChange(event: boolean): void {
    if (!event && this.destinationSearchText !== this.priorDestinationSearchText) {
      this.searchDestination();
    }
  }


  /**
* Search for a Media Id
*/
  searchMediaId(): void {
    this.resetTableParams();
    this.mediaIdSearchVisible = false;
    if (this.mediaIdSearchText.length === 0) {
      delete this.apiQueryFilters.filter.mediaID;
    } else {
      this.apiQueryFilters.filter.mediaID = this.mediaIdSearchText + '*';
    }
    this.priorMediaIdSearchText = this.mediaIdSearchText;
    this.loadRelatedContent();
  }

  resetMediaIdSearch(): void {
    this.mediaIdSearchVisible = false;
    this.mediaIdSearchText = '';
    delete this.apiQueryFilters.filter.mediaID;
    this.priorMediaIdSearchText = '';
    this.loadRelatedContent();
  }

  mediaIdVisibleChange(event: boolean): void {
    if (!event && this.mediaIdSearchText !== this.priorMediaIdSearchText) {
      this.searchMediaId();
    }
  }

  /**
  * Open selected ProdReq app for the environment
  *
  */
  openApp(url: string, key: NBCApp): boolean {

    if (this.navigationService.isIOSMobileApp.value) {
      return true;
    } else {
      const win = window.open();
      if (win) {
        win.opener = null;
        win.location.href = url;
        this.gaService.sendEvent('Story Landing - Related Content', `Open ${key}`, url, 1);
        return false;
      }
    }
  }

  windowResize() {
    // this.heightToBeRemoved = (32 + 16) + (16 * 3) + (24 * 2) + (40 * 2);

    // this.tableHeight = this.relatedContentHeight - this.heightToBeRemoved;

    // this.resizeColumnWidths();
  }

}


