<div #tableHeaderSearch>
   <div class="table-header-search">
      <nz-input-group
         nzSearch
         [nzAddOnAfter]="suffixIconButton">
         <input
            type="text"
            nz-input
            (ngModelChange)="search()"
            [(ngModel)]="idSearchText"
            placeholder="Search" />
      </nz-input-group>
      <ng-template #suffixIconButton>
         <button
            (click)="searchId()"
            nz-button
            nzSearch>
            <span
               nz-icon
               nzType="search"></span>
         </button>
      </ng-template>
   </div>
</div>

<div #editRequestTable>
   <nz-table
      nzBordered
      class="related-content-table"
      [ngClass]="{ 'no-content-table': relatedContentData.length == 0 }"
      [nzData]="relatedContentData"
      [nzFrontPagination]="false"
      [nzTotal]="tableParams.total"
      [nzPageIndex]="tableParams.page"
      [nzPageSize]="tableParams.limit"
      nzShowSizeChanger
      [nzLoading]="!isLoaded"
      [nzLoadingDelay]="750"
      (nzPageIndexChange)="onChangePage($event)"
      (nzPageSizeChange)="onChangePageSize($event)"
      [nzScroll]="{ y: tableHeight + 'px' }"
      nzShowQuickJumper>
      <thead #tableHeader>
         <tr>
            <th
               [nzWidth]="preferenceColumns['TYPE'].width + 'px'"
               [rowspan]="preferenceColumns['TYPE'].rowSpan"
               [nzFilters]="typeFilters"
               [nzFilterFn]="true"
               (nzFilterChange)="typeFilterEvent($event)">
               {{ preferenceColumns['TYPE'].text }}
            </th>

            <th
               [nzWidth]="preferenceColumns['IDSTATUS'].width + 'px'"
               [nzFilters]="statusFilters"
               [nzFilterFn]="true"
               (nzFilterChange)="statusFilterEvent($event)">
               {{ preferenceColumns['IDSTATUS'].text }}
               <nz-filter-trigger
                  [(nzVisible)]="idSearchVisible"
                  [nzActive]="idSearchText.length > 0"
                  [nzDropdownMenu]="idTemplate">
                  <span
                     nz-icon
                     nzType="search"></span>
               </nz-filter-trigger>
            </th>

            <th
               [nzWidth]="preferenceColumns['AIRDATE'].width + 'px'"
               [nzLeft]="false"
               [rowspan]="preferenceColumns['AIRDATE'].rowSpan"
               [nzSortFn]="true"
               [nzSortOrder]="airDateSortOrder"
               (nzSortOrderChange)="airDateSortEvent($event)"
               [nzFilters]="airDateFilters"
               [nzFilterFn]="true"
               (nzFilterChange)="airDateFilterEvent($event)">
               {{ preferenceColumns['AIRDATE'].text }}
            </th>

            <th
               [nzWidth]="preferenceColumns['REQUESTER'].width + 'px'"
               nzCustomFilter>
               {{ preferenceColumns['REQUESTER'].text }}
               <nz-filter-trigger
                  [(nzVisible)]="requesterSearchVisible"
                  [nzActive]="requesterSearchText.length > 0"
                  [nzDropdownMenu]="requesterTemplate"
                  (nzVisibleChange)="requesterVisibleChange(requesterSearchVisible)">
                  <span
                     nz-icon
                     nzType="search"></span>
               </nz-filter-trigger>
            </th>

            <th
               [nzWidth]="preferenceColumns['SHOWPROJECT'].width + 'px'"
               [rowspan]="preferenceColumns['SHOWPROJECT'].rowSpan"
               nzCustomFilter>
               {{ preferenceColumns['SHOWPROJECT'].text }}
               <nz-filter-trigger
                  [(nzVisible)]="showOrProjectSearchVisible"
                  [nzActive]="showOrProjectSearchText.length > 0"
                  [nzDropdownMenu]="showOrProjectTemplate"
                  (nzVisibleChange)="ShowOrProjectVisibleChange(showOrProjectSearchVisible)">
                  <span
                     nz-icon
                     nzType="search"></span>
               </nz-filter-trigger>
            </th>

            <th
               [nzWidth]="preferenceColumns['PRODUCER'].width + 'px'"
               nzCustomFilter>
               {{ preferenceColumns['PRODUCER'].text }}
               <nz-filter-trigger
                  [(nzVisible)]="producerSearchVisible"
                  [nzActive]="producerSearchText.length > 0"
                  [nzDropdownMenu]="producerTemplate"
                  (nzVisibleChange)="producerVisibleChange(requesterSearchVisible)">
                  <span
                     nz-icon
                     nzType="search"></span>
               </nz-filter-trigger>
            </th>

            <th
               [nzWidth]="preferenceColumns['SRPRODUCER'].width + 'px'"
               nzCustomFilter>
               {{ preferenceColumns['SRPRODUCER'].text }}
               <nz-filter-trigger
                  [(nzVisible)]="srProducerSearchVisible"
                  [nzActive]="srProducerSearchText.length > 0"
                  [nzDropdownMenu]="srProducerTemplate"
                  (nzVisibleChange)="srProducerVisibleChange(requesterSearchVisible)">
                  <span
                     nz-icon
                     nzType="search"></span>
               </nz-filter-trigger>
            </th>
         </tr>
      </thead>
      <tbody>
         <ng-container *ngFor="let data of relatedContentData; let i = index">
            <tr>
               <td>{{ data.content.requestType }}</td>

               <td>
                  <a
                     class="flex"
                     [ngStyle]="{ color: requestStatusDetails[data.content.statusIndex]?.color }"
                     (click)="openApp(data.content.zrouterlink, 'producer-dashboard')"
                     [href]="data.content.zrouterlink">
                     <span
                        [style.color]="requestStatusDetails[data.content.statusIndex]?.color"
                        [ngClass]="requestStatusDetails[data.content.statusIndex]?.class">
                        <span
                           nz-icon
                           class="status-icon"
                           [nzType]="requestStatusDetails[data.content.statusIndex]?.icon"
                           [nzTheme]="
                              requestStatusDetails[data.content.statusIndex]?.icon === 'sync' ? 'outline' : 'fill'
                           "></span>
                        <span
                           nz-tooltip
                           nzTooltipPlacement="bottomLeft"
                           nzTooltipColor="white">
                           {{ data.content.zid }}
                        </span>
                     </span>
                     <span
                        nz-icon
                        class="id-right-icon"
                        nzType="right"
                        nzTheme="outline"></span>
                  </a>
               </td>

               <td class="date-right-align">
                  {{
                     data.content.airDateTBD == 'Yes'
                        ? 'TBD'
                        : data.content.airDate == 0
                          ? ''
                          : data.content.airDateString
                  }}
               </td>

               <td [nzEllipsis]="true">
                  <!-- <span
                     *ngIf="data.content.zRequestersRemaining"
                     nz-popover
                     [nzPopoverTitle]=""
                     [nzPopoverContent]="data.content.zRequestersDisplayList">
                     {{ data.content.zRequestersDisplayString }}
                  </span>

                  <span *ngIf="!data.content.zRequestersRemaining">{{ data.content.zRequestersDisplayString }}</span> -->

                  <span
                     nz-popover
                     [nzPopoverTitle]=""
                     [nzPopoverContent]="data.content.requestorName">
                     {{ data.content.requestorName }}
                  </span>
               </td>

               <td [nzEllipsis]="true">
                  <!-- <span
                     *ngIf="data.content.zShowUnitsRemaining"
                     nz-popover
                     [nzPopoverTitle]="showUnitTitleTemplate"
                     [nzPopoverContent]="showUnitDataTemplate">
                     {{ data.content.zShowUnitsDisplayString }}
                  </span>

                  <span *ngIf="!data.content.zShowUnitsRemaining">{{ data.content.zShowUnitsDisplayString }}</span> -->

                  <span
                     nz-popover
                     nzPopoverPlacement="topLeft"
                     [nzPopoverTitle]="showUnitTitleTemplate"
                     [nzPopoverContent]="showUnitDataTemplate">
                     {{ data.content.zShowUnitsDisplayString }}

                     <ng-template #showUnitTitleTemplate>
                        <span class="popover-title">Show / Project</span>
                     </ng-template>

                     <ng-template #showUnitDataTemplate>
                        <div class="popover-content">
                           <ng-container *ngFor="let show of data.content.zShowUnitsDisplayList">
                              <ul class="list-items">
                                 <li class="">{{ show }}</li>
                              </ul>
                           </ng-container>
                        </div>
                     </ng-template>
                  </span>
               </td>

               <td [nzEllipsis]="true">
                  <!-- <span
                     *ngIf="data.content.zProducerRemaining"
                     nz-popover
                     [nzPopoverTitle]=""
                     [nzPopoverContent]="data.content.zProducerDisplayList">
                     {{ data.content.zProducerDisplayString }}
                  </span>

                  <span *ngIf="!data.content.zProducerRemaining">{{ data.content.zProducerDisplayString }}</span> -->

                  <span
                     nz-popover
                     [nzPopoverTitle]=""
                     [nzPopoverContent]="data.content.producerName">
                     {{ data.content.producerName }}
                  </span>
               </td>

               <td [nzEllipsis]="true">
                  <!-- <span
                     *ngIf="data.content.zSrProducerRemaining"
                     nz-popover
                     [nzPopoverTitle]=""
                     [nzPopoverContent]="data.content.zSrProducerDisplayList">
                     {{ data.content.zSrProducerDisplayString }}
                  </span>

                  <span *ngIf="!data.content.zSrProducerRemaining">{{ data.content.zSrProducerDisplayString }}</span> -->

                  <span
                     nz-popover
                     [nzPopoverTitle]=""
                     [nzPopoverContent]="data.content.seniorProducer">
                     {{ data.content.seniorProducer }}
                  </span>
               </td>
            </tr>
         </ng-container>
      </tbody>
   </nz-table>
</div>

<!-- GUEST COLUMN SEARCH -->
<nz-dropdown-menu
   #idTemplate
   Menu="nzDropdownMenu">
   <div class="search-filter">
      <div class="search-box">
         <input
            type="text"
            nz-input
            placeholder="Search Id(s)"
            (keyup.enter)="searchId()"
            [(ngModel)]="idSearchText" />
         <button
            nz-button
            nzSize="small"
            nzType="primary"
            (click)="searchId()"
            class="search-button"
            [ngClass]="idSearchText != '' ? 'search-reset-button-on-input' : ''">
            Search
         </button>
         <button
            nz-button
            nzSize="small"
            (click)="resetIdSearch()"
            [ngClass]="idSearchText != '' ? 'search-reset-button-on-input' : ''">
            Reset
         </button>
      </div>
   </div>
</nz-dropdown-menu>

<nz-dropdown-menu
   #requesterTemplate
   Menu="nzDropdownMenu">
   <div class="search-filter">
      <div class="search-box">
         <input
            type="text"
            nz-input
            placeholder="Search Requester(s)"
            (keyup.enter)="searchRequester()"
            [(ngModel)]="requesterSearchText" />
         <button
            nz-button
            nzSize="small"
            nzType="primary"
            (click)="searchRequester()"
            class="search-button"
            [ngClass]="requesterSearchText != '' ? 'search-reset-button-on-input' : ''">
            Search
         </button>
         <button
            nz-button
            nzSize="small"
            (click)="resetRequesterSearch()"
            [ngClass]="requesterSearchText != '' ? 'search-reset-button-on-input' : ''">
            Reset
         </button>
      </div>
   </div>
</nz-dropdown-menu>

<nz-dropdown-menu
   #showOrProjectTemplate
   Menu="nzDropdownMenu">
   <div class="search-filter">
      <div class="search-box">
         <input
            type="text"
            nz-input
            placeholder="Search Show / Project"
            (keyup.enter)="searchShowOrProject()"
            [(ngModel)]="showOrProjectSearchText" />
         <button
            nz-button
            nzSize="small"
            nzType="primary"
            (click)="searchShowOrProject()"
            class="search-button"
            [ngClass]="showOrProjectSearchText != '' ? 'search-reset-button-on-input' : ''">
            Search
         </button>
         <button
            nz-button
            nzSize="small"
            (click)="resetShowOrProjectSearch()"
            [ngClass]="showOrProjectSearchText != '' ? 'search-reset-button-on-input' : ''">
            Reset
         </button>
      </div>
   </div>
</nz-dropdown-menu>

<nz-dropdown-menu
   #producerTemplate
   Menu="nzDropdownMenu">
   <div class="search-filter">
      <div class="search-box">
         <input
            type="text"
            nz-input
            placeholder="Search Producer(s)"
            (keyup.enter)="searchProducer()"
            [(ngModel)]="producerSearchText" />
         <button
            nz-button
            nzSize="small"
            nzType="primary"
            (click)="searchProducer()"
            class="search-button"
            [ngClass]="producerSearchText != '' ? 'search-reset-button-on-input' : ''">
            Search
         </button>
         <button
            nz-button
            nzSize="small"
            (click)="resetProducerSearch()"
            [ngClass]="producerSearchText != '' ? 'search-reset-button-on-input' : ''">
            Reset
         </button>
      </div>
   </div>
</nz-dropdown-menu>

<nz-dropdown-menu
   #srProducerTemplate
   Menu="nzDropdownMenu">
   <div class="search-filter">
      <div class="search-box">
         <input
            type="text"
            nz-input
            placeholder="Search Senior Producer(s)"
            (keyup.enter)="searchSrProducer()"
            [(ngModel)]="srProducerSearchText" />
         <button
            nz-button
            nzSize="small"
            nzType="primary"
            (click)="searchSrProducer()"
            class="search-button"
            [ngClass]="srProducerSearchText != '' ? 'search-reset-button-on-input' : ''">
            Search
         </button>
         <button
            nz-button
            nzSize="small"
            (click)="resetSrProducerSearch()"
            [ngClass]="srProducerSearchText != '' ? 'search-reset-button-on-input' : ''">
            Reset
         </button>
      </div>
   </div>
</nz-dropdown-menu>
