<div
   class="preview"
   *ngIf="!showTabs">
   <app-search-results-preview
      [searchText]="searchText"
      (seeAllResults)="seeAllResults()"></app-search-results-preview>
</div>

<div class="search-results">
   <div
      [ngClass]="{
         'search-box-padding': true,
      }">
      <div
         [ngClass]="{
            'search-box': true,
            'search-box-border': suggestions.length > 0,
         }">
         <div
            [ngClass]="{
               'search-input': true,
               'search-input-default-bg': true,
               'search-input-filled-bg': suggestionSelected,
               'search-input-suggestions-bg': suggestions.length > 0,
            }">
            <div class="search-icon">
               <span
                  nz-icon
                  nzType="search"
                  nzTheme="outline"></span>
            </div>
            <div class="search-input-text-div">
               <input
                  #searchInputField
                  type="text"
                  [ngClass]="{
                     'search-input-text': true,
                     'search-input-default-bg': true,
                     'search-input-filled-bg': suggestionSelected,
                     'search-input-suggestions-bg': suggestions.length > 0,
                  }"
                  placeholder="Search NewsConnect..."
                  (input)="onInput($event)"
                  (focus)="onFocus()"
                  (keydown.enter)="setSearchTerm($event)"
                  [(ngModel)]="searchTerm" />
            </div>
            <div
               class="close-icon"
               *ngIf="searchTerm != ''">
               <button (click)="clearSearchTerm()">
                  <i
                     nz-icon
                     nzType="close-circle"
                     nzTheme="fill"></i>
               </button>
            </div>
         </div>

         <div
            class="suggestions-list"
            *ngIf="suggestions.length > 0">
            <ul class="flex-suggestions">
               <li
                  class="suggestion-item"
                  *ngFor="let suggestion of suggestions">
                  <div class="history-icon">
                     <button>
                        <i
                           nz-icon
                           nzType="history"
                           nzTheme="outline"></i>
                     </button>
                  </div>
                  <div (click)="selectSuggestion(suggestion)">{{ suggestion }}</div>
                  <div class="delete">
                     <button (click)="removeSearchTerm(suggestion)">
                        <i
                           nz-icon
                           nzType="close-circle"
                           nzTheme="fill"></i>
                     </button>
                  </div>
               </li>
            </ul>
         </div>

         <ng-template #prefixTemplateUser>
            <i
               nz-icon
               nzType="search"
               class="search-icon"
               nzTheme="outline"></i>
         </ng-template>
         <ng-template #suffixTemplateInfo>
            <button (click)="clearSearchTerm()">
               <i
                  nz-icon
                  nzType="close-circle"
                  nzTheme="fill"
                  class="close-circle"></i>
            </button>
         </ng-template>
      </div>
   </div>

   <div [ngClass]="{ 'ant-layout-has-sider': true }">
      <div class="search-results-tabs">
         <nz-tabset
            (nzSelectChange)="onChangeTab($event)"
            [(nzSelectedIndex)]="tabIndex">
            <!----------- ALL ----------->

            <nz-tab nzTitle="All">
               <app-all-results [isVisible]="tabIndex === 0"></app-all-results>
            </nz-tab>

            <!----------- STORIES ----------->

            <nz-tab nzTitle="Stories">
               <app-stories-results-new [isVisible]="tabIndex === 1"></app-stories-results-new>
            </nz-tab>

            <!----------- POSTS ----------->

            <nz-tab nzTitle="Posts">
               <app-post-results-new [isVisible]="tabIndex === 2"></app-post-results-new>
            </nz-tab>

            <!----------- ELEMENTS ----------->

            <nz-tab nzTitle="Elements">
               <app-element-results [isVisible]="tabIndex === 3"></app-element-results>
            </nz-tab>

            <!----------- ANGLES ----------->

            <nz-tab [nzTitle]="titleTemplate">
               <app-angle-results-new [isVisible]="tabIndex === 4"></app-angle-results-new>
               <ng-template #titleTemplate>Angles</ng-template>
            </nz-tab>

            <!----------- GROUPS ----------->

            <nz-tab nzTitle="Groups">
               <app-group-results-new [isVisible]="tabIndex === 5"></app-group-results-new>
            </nz-tab>

            <!----------- PEOPLE ----------->

            <nz-tab nzTitle="People">
               <app-people-results-new [isVisible]="tabIndex === 6"></app-people-results-new>
            </nz-tab>
         </nz-tabset>
      </div>
   </div>
</div>
