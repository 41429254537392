import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root'
})

export class CSSThemeService {

  // Should stay empty so that the theme is initalized correctly
  currentTheme = '';

  // Subject when theme changes
  public readonly theme: BehaviorSubject<string> = new BehaviorSubject<string>(this.currentTheme);

  public theme$: Observable<string> = this.theme.asObservable();

  loadCss(href: string, id: string): Promise<Event> {

    return new Promise((resolve, reject) => {

      const style = document.createElement('link');

      style.rel = 'stylesheet';
      style.id = `custom-theme-${id}`;
      style.href = `${href}?ver=${new Date().getTime()}`;
      style.onload = resolve;
      style.onerror = reject;
      document.head.append(style);

    });

  }

  removeUnusedTheme(oldTheme: string): void {

    const stylesheet = document.getElementById(`custom-theme-${oldTheme}`);

    if (stylesheet) {

      stylesheet.parentNode.removeChild(stylesheet);
      console.log(`Remove Unused Theme "${oldTheme}"`, { stylesheet });

    }

  }

  loadTheme(theme: 'default' | 'dark'): void {

    if (theme === this.currentTheme) {

      return;

    }
    console.log('Load New Theme', { theme });
    const body = document.getElementsByTagName('body')[0];

    const oldTheme = this.currentTheme == '' ? 'default' : theme === 'dark' ? 'default' : 'dark';

    this.loadCss(`${theme}.css`, theme).then(() => {

      this.currentTheme = theme;
      this.theme.next(theme);
      body.classList.remove('default', 'dark');
      body.classList.add(theme);
      this.removeUnusedTheme(oldTheme);

    });

  }

}
