<app-loader-component [isLoaded]="isLoaded"></app-loader-component>

<div
   class="view-story"
   [attr.data-component]="'ncx/view-story/view-story.component.html'">
   <!-- HEADER -->
   <app-standard-header
      [defaultBackAction]="true"
      title="Back"></app-standard-header>
   <div
      *ngIf="!isXsmall"
      class="extraFeatures background center-align-flex">
      <div class="formatButtons display-body">
         <button
            nz-button
            *ngIf="canEditStory"
            (click)="isEdit(true)"
            nzType="primary">
            <i
               nz-icon
               nzType="edit"
               nzTheme="fill"></i>
            Edit Story
         </button>
         <button
            nz-button
            *ngIf="functionAbility.fa_merge_story"
            (click)="viewMergeStory()">
            <i
               nz-icon
               nzType="swap"
               nzTheme="outline"></i>
            Merge Story
         </button>

         <button
            nz-button
            nzDanger="true"
            class="delete"
            (click)="warning()"
            *ngIf="functionAbility.fa_delete_story">
            <i
               nz-icon
               nzType="delete"
               nzTheme="fill"></i>
            Delete Story
         </button>

         <ng-container *ngIf="functionAbility.fa_access_admin_console">
            <span class="right-align">
               <span class="block-slack">Block from Slack</span>
               <nz-switch
                  [(ngModel)]="isSlackBlockedSubscription"
                  (click)="blockFromSlack()"
                  nzSize="small"></nz-switch>
            </span>
         </ng-container>
      </div>
   </div>
   <div
      *ngIf="isXsmall"
      class="extraFeatures background">
      <div class="formatButtons">
         <button
            class="smallBtn"
            nz-button
            *ngIf="canEditStory"
            (click)="isEdit(true)"
            nzType="primary"
            nzBlock="true">
            <i
               nz-icon
               nzType="edit"
               nzTheme="fill"></i>
            Edit
         </button>

         <button
            nz-button
            class="smallBtn"
            nzBlock="true"
            *ngIf="functionAbility.fa_merge_story"
            (click)="viewMergeStory()">
            <i
               nz-icon
               nzType="swap"
               nzTheme="outline"></i>
            Merge
         </button>

         <button
            nz-button
            nzBlock="true"
            nzDanger="true"
            class="smallBtn delete"
            (click)="warning()"
            *ngIf="functionAbility.fa_delete_story">
            <i
               nz-icon
               nzType="delete"
               nzTheme="fill"></i>
            Delete
         </button>
      </div>
      <div>
         <ng-container *ngIf="functionAbility.fa_access_admin_console">
            <span class="customBtn">
               <span>Block from Slack</span>
               <span class="alignBtn">
                  <nz-switch
                     [(ngModel)]="isSlackBlockedSubscription"
                     (click)="blockFromSlack()"
                     nzSize="small"></nz-switch>
               </span>
            </span>
         </ng-container>
      </div>
   </div>
   <div
      #storyDescContent
      class="display-story center-align-flex">
      <app-detail-story
         class="display-body"
         [storyDetails]="storyDetails"></app-detail-story>
   </div>
   <nz-footer class="footer-flex">
      <div
         *ngIf="!isSmall"
         class="extraFeatures formatFooterButtons">
         <button
            nz-button
            (click)="openInfoCenter()"
            *ngIf="functionAbility.fa_delete_story">
            <i
               nz-icon
               nzType="info-circle"
               nzTheme="outline"></i>
            Infocenter
         </button>
         <a
            nz-button
            href="{{ viewStoryEmailTo }}"
            *ngIf="functionAbility.fa_add_post_via_email && storyDetails?.isStoryPublished">
            <i
               nz-icon
               nzType="mail"
               nzTheme="outline"></i>
            Add Post via Email
         </a>
      </div>
      <div
         *ngIf="isSmall"
         class="extraFeatures formatFooterButtons">
         <button
            nz-button
            (click)="openInfoCenter()"
            *ngIf="functionAbility.fa_delete_story">
            <i
               nz-icon
               nzType="info-circle"
               nzTheme="outline"></i>
         </button>
         <a
            nz-button
            href="{{ viewStoryEmailTo }}"
            *ngIf="functionAbility.fa_add_post_via_email">
            <i
               nz-icon
               nzType="mail"
               nzTheme="outline"></i>
         </a>
      </div>
   </nz-footer>
</div>

<app-merge-stories
   [isMergeStoryVisible]="isMergeStoryVisible"
   [sourceStoryId]="storyId"
   (closeMergeStory)="closeMergeStory()"></app-merge-stories>

<app-infocenter-research
   [isinfocenterVisible]="isinfocenterVisible"
   [storyTitle]="storyTitle"
   [Id]="storyId"
   (closeinfoCenter)="closeInfoCenter()"></app-infocenter-research>
