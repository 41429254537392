
export interface CrewShowUnit {
    budgetCode: string;

    // required
    id: number;

    // required
    showunittitle: string;
}

export function manipulateData(dataFromAPI: any[]) {
    manipulateEditData(dataFromAPI);
}

export function manipulateGTData(url: string, dataFromAPI: any[]) {
    dataFromAPI?.forEach(record => {

        record.zid = record.guestId;

        record.zrouterlink = url + record.appearId;

        record.expand = false;

        record.guestName = record.guestFirstName + ' ' + record.guestMiddleName + (record.guestMiddleName == '' ? '' : ' ') + record.guestLastName;

        record.zOnAirDateTime = formatDateToShowTimeZone(convertUnixToDate(record.onAirDateTime));
    });
}


export function manipulateCrewData(dataFromAPI: any[]) {
    dataFromAPI?.forEach(record => {

        record.content.zid = record.headers.id;

        record.content.zrouterlink = record.content.targetURL;

        record.content.statusIndex = record.content.requestStatus;

        record.content.requestType = record.content.crewType;

        record.expand = false;
        record.content.requestCreated = formatDateToShowTimeZone(convertUnixToDate(record.content.created));
        record.content.requestModified = formatDateToShowTimeZone(convertUnixToDate(record.content.modified));

        record.content.ellipsis = false;


        let location = truncateString(record.content.location);
        record.content.zLocationDisplayString = location.truncated;
        record.content.zLocationDisplayList = location.list;
        record.content.zLocationRemaining = location.remaining;
        record.content.zLocationRemainingLength = location.remainingLength;

        // let requesters = buildRequesters(record);
        let requesters = truncateString(record.content.requester);
        record.content.zRequestersDisplayString = requesters.truncated;
        record.content.zRequestersDisplayList = requesters.list;
        record.content.zRequestersTruncatedList = requesters.truncatedList;
        record.content.zRequestersRemaining = requesters.remaining;
        record.content.zRequestersRemainingLength = requesters.remainingLength;

        let showUnits = buildShowUnits(record);
        record.content.zShowUnitsDisplayString = showUnits.fullString;
        record.content.zShowUnitsDisplayList = showUnits.list;
        record.content.zShowUnitsRemaining = showUnits.remaining;
        record.content.zShowUnitsRemainingLength = showUnits.remainingLength;

        let fieldContact = truncateString(record.content.fieldContactName);
        record.content.zFieldContactDisplayString = fieldContact.truncated;
        record.content.zFieldContactDisplayList = fieldContact.list;
        record.content.zFieldContactTruncatedList = fieldContact.truncatedList;
        record.content.zFieldContactRemaining = fieldContact.remaining;
        record.content.zFieldContactRemainingLength = fieldContact.remainingLength;

        // let producer = truncateString(record.content.producerName);
        // record.content.zProducerDisplayString = producer.truncated;
        // record.content.zProducerTruncatedList = producer.truncatedList;
        // record.content.zProducerDisplayList = producer.list;
        // record.content.zProducerRemaining = producer.remaining;
        // record.content.zProducerRemainingLength = producer.remainingLength;

        // let srProducer = truncateString(record.content.seniorProducer);
        // record.content.zSrProducerDisplayString = srProducer.truncated;
        // record.content.zSrProducerTruncatedList = srProducer.truncatedList;
        // record.content.zSrProducerDisplayList = srProducer.list;
        // record.content.zSrProducerRemaining = srProducer.remaining;
        // record.content.zSrProducerRemainingLength = srProducer.remainingLength;
    });
}

export function manipulateFileIngestData(dataFromAPI: any[]) {
    dataFromAPI?.forEach(record => {

        record.content.zid = record.headers.id;

        record.content.zrouterlink = record.content.targetURL;

        // record.content.statusIndex = record.content.statusIndex;

        record.expand = false;
        record.content.requestCreated = formatDateToShowTimeZone(convertUnixToDate(record.content.created));

        record.content.requestModified = formatDateToShowTimeZone(convertUnixToDate(record.content.modified));

        record.content.requestAirDate = formatDateToShowTimeZone(convertUnixToDate(record.content.airDate));

        let showUnits = buildFileIngestShowUnits(record);
        record.content.zShowUnitsDisplayString = showUnits.fullString;//.truncated;
        record.content.zShowUnitsDisplayList = showUnits.list;
        record.content.zShowUnitsRemaining = showUnits.remaining;
        record.content.zShowUnitsRemainingLength = showUnits.remainingLength;

        let destinations = truncateArray(record.content.destinations);
        record.content.zDestinationsDisplayString = destinations.fullString;//truncated;
        record.content.zDestinationsDisplayList = destinations.list;
        record.content.zDestinationsRemaining = destinations.remaining;
        record.content.zDestinationsRemainingLength = destinations.remainingLength;

        let requesters = truncateString(record.content.requestorName);
        record.content.zRequestersDisplayString = requesters.truncated;
        record.content.zRequestersDisplayList = requesters.list;
        record.content.zRequestersTruncatedList = requesters.truncatedList;
        record.content.zRequestersRemaining = requesters.remaining;
        record.content.zRequestersRemainingLength = requesters.remainingLength;
    });
}

export function manipulateFeedOutData(dataFromAPI: any[]) {
    dataFromAPI?.forEach(record => {

        record.content.zid = record.headers.id;

        record.content.zrouterlink = record.content.targetURL;

        record.content.statusIndex = record.content.feedOutStatus;

        record.expand = false;
        record.content.requestCreated = formatDateToShowTimeZone(convertUnixToDate(record.content.created));

        record.content.requestModified = formatDateToShowTimeZone(convertUnixToDate(record.content.modified));

        // record.content.showunit.push({ budgetCode: '', id: 0, showunittitle: 'asdfas dfasdf asdf asdf asdfa' });
        // record.content.showunit.push({ budgetCode: '', id: 0, showunittitle: 'sdasdfas dfasdf asdf asdf asdfa' });
        // record.content.showunit.push({ budgetCode: '', id: 0, showunittitle: 'ghjasdfas dfasdf asdf asdf asdfa' });
        // record.content.showunit.push({ budgetCode: '', id: 0, showunittitle: ',ggjhwasdfas dfasdf asdf asdf asdfa' });
        // record.content.showunit.push({ budgetCode: '', id: 0, showunittitle: 'wer2asdfas dfasdf asdf asdf asdfa' });
        // record.content.showunit.push({ budgetCode: '', id: 0, showunittitle: 'dfg,dfgasdfas dfasdf asdf asdf asdfa' });
        // record.content.showunit.push({ budgetCode: '', id: 0, showunittitle: 'dfgh65asdfas dfasdf asdf asdf asdfa' });
        // record.content.showunit.push({ budgetCode: '', id: 0, showunittitle: 'gfdncgjtasdfas dfasdf asdf asdf asdfa' });

        let showUnits = buildShowUnits(record);
        record.content.zShowUnitsDisplayString = showUnits.fullString;//.truncated;
        record.content.zShowUnitsDisplayList = showUnits.list;
        record.content.zShowUnitsRemaining = showUnits.remaining;
        record.content.zShowUnitsRemainingLength = showUnits.remainingLength;

        // let destinations = buildDestinations(record);

        // record.content.destinations.push('asdfas dfasdf asdf asdf asdfa');
        // record.content.destinations.push('345asdfas dfasdf asdf asdf asdfa');
        // record.content.destinations.push('cbncvbasdfas dfasdf asdf asdf asdfa');
        // record.content.destinations.push('5677asdfas dfasdf asdf asdf asdfa');
        // record.content.destinations.push('sdfghj5e76thasdfas dfasdf asdf asdf asdfa');

        let destinations = truncateArray(record.content.destinations);
        record.content.zDestinationsDisplayString = destinations.fullString;//truncated;
        record.content.zDestinationsDisplayList = destinations.list;
        record.content.zDestinationsRemaining = destinations.remaining;
        record.content.zDestinationsRemainingLength = destinations.remainingLength;

        let requesters = truncateString(record.content.requestorName);
        record.content.zRequestersDisplayString = requesters.truncated;
        record.content.zRequestersDisplayList = requesters.list;
        record.content.zRequestersTruncatedList = requesters.truncatedList;
        record.content.zRequestersRemaining = requesters.remaining;
        record.content.zRequestersRemainingLength = requesters.remainingLength;
    });
}

export function manipulateEditData(dataFromAPI: any[]) {
    dataFromAPI?.forEach(record => {
        record.content.zid = record.headers.id;

        record.content.zrouterlink = record.content.targetURL;

        record.content.statusIndex = record.content.erRequestStatus;

        record.content.requestType = record.content.editType == 'Long Form Edit Request' ? 'Long Form' : 'Standard';

        record.expand = false;
        record.content.requestCreated = formatDateToShowTimeZone(convertUnixToDate(record.content.created));
        record.content.requestModified = formatDateToShowTimeZone(convertUnixToDate(record.content.modified));


        // record.content.showunit.push({ budgetCode: '', id: 0, showunittitle: 'asdfas dfasdf asdf asdf asdfa' });
        // record.content.showunit.push({ budgetCode: '', id: 0, showunittitle: 'sdasdfas dfasdf asdf asdf asdfa' });
        // record.content.showunit.push({ budgetCode: '', id: 0, showunittitle: 'ghjasdfas dfasdf asdf asdf asdfa' });
        // record.content.showunit.push({ budgetCode: '', id: 0, showunittitle: ',ggjhwasdfas dfasdf asdf asdf asdfa' });
        // record.content.showunit.push({ budgetCode: '', id: 0, showunittitle: 'wer2asdfas dfasdf asdf asdf asdfa' });
        // record.content.showunit.push({ budgetCode: '', id: 0, showunittitle: 'dfg,dfgasdfas dfasdf asdf asdf asdfa' });
        // record.content.showunit.push({ budgetCode: '', id: 0, showunittitle: 'dfgh65asdfas dfasdf asdf asdf asdfa' });
        // record.content.showunit.push({ budgetCode: '', id: 0, showunittitle: 'gfdncgjtasdfas dfasdf asdf asdf asdfa' });


        let showUnits = buildShowUnits(record);
        record.content.zShowUnitsDisplayString = showUnits.fullString;//.truncated;
        record.content.zShowUnitsDisplayList = showUnits.list;
        record.content.zShowUnitsRemaining = showUnits.remaining;
        record.content.zShowUnitsRemainingLength = showUnits.remainingLength;

        // let requesters = buildRequesters(record);
        let requesters = truncateString(record.content.requestorName);
        record.content.zRequestersDisplayString = requesters.truncated;
        record.content.zRequestersDisplayList = requesters.list;
        record.content.zRequestersTruncatedList = requesters.truncatedList;
        record.content.zRequestersRemaining = requesters.remaining;
        record.content.zRequestersRemainingLength = requesters.remainingLength;

        let producer = truncateString(record.content.producerName);
        record.content.zProducerDisplayString = producer.truncated;
        record.content.zProducerTruncatedList = producer.truncatedList;
        record.content.zProducerDisplayList = producer.list;
        record.content.zProducerRemaining = producer.remaining;
        record.content.zProducerRemainingLength = producer.remainingLength;

        let srProducer = truncateString(record.content.seniorProducer);
        record.content.zSrProducerDisplayString = srProducer.truncated;
        record.content.zSrProducerTruncatedList = srProducer.truncatedList;
        record.content.zSrProducerDisplayList = srProducer.list;
        record.content.zSrProducerRemaining = srProducer.remaining;
        record.content.zSrProducerRemainingLength = srProducer.remainingLength;
    });
}

export function setIdAndRouterLink(record: any) {
    // if (record.content.requestType === requestType.feedout) {
    //     record.content.zid = record.content.feedOutRequestID;
    //     record.content.zrouterlink = '/unified-requests/feed-out';
    // } else if (record.content.requestType === requestType.fileingest) {
    //     record.content.zid = record.content.fileIngestID;
    //     record.content.zrouterlink = '/unified-requests/file-ingest';
    // } else if (record.content.requestType === requestType.mtd) {
    //     record.content.zid = record.content.mtdRequestID;
    //     record.content.zrouterlink = '/unified-requests/mtd';
    // }

    record.content.zid = record.headers.id;
    // record.content.zrouterlink = record.targetURL.targetURL;
}

/*
* Build Show Units information for tables
*/
export function buildFileIngestShowUnits(record: any): any {
    let showUnits: CrewShowUnit[] = record.content.showunits;
    return truncateArray(showUnits?.map((showUnit, index) => { return showUnit?.showunittitle; }));
}

/*
* Build Show Units information for tables
*/
export function buildShowUnits(record: any): any {
    let showUnits: CrewShowUnit[] = record.content.showunit;
    return truncateArray(showUnits?.map((showUnit, index) => { return showUnit?.showunittitle; }));
}

/*
* Build Requesters information for tables
*/
export function buildRequesters(record: any) {
    return truncateArray(record.content.requesters?.map((requester: any) => { return requester.displayName; }));
}

/*
 * Build Destinations information for tables
 */
export function buildDestinations(record: any): any {
    return truncateArray(record.content.destinations);
}


/**
* Truncates a list to X num of characters
*
* @param values {string[]}
* @param appendedChar {string}
* @param length {number}
*
*/
export function truncateList(values: string[] = [], appendedChar = ', ') {
    try {
        values = values ? values : [];

        let truncatedList = values.slice(0, 3);
        let truncated = truncatedList.join(appendedChar);

        let remainingList = values.slice(3);

        let remainingLength = remainingList.length;
        let remaining = remainingList.length > 0;
        return {
            list: values,
            truncated: truncated,
            truncatedList: truncatedList,
            remainingList: remainingList,
            remaining: remaining,
            remainingLength: remainingLength == 0 ? '' : '+' + remainingLength,
        };
    } catch (error) {
        return { list: [], truncated: '', truncatedList: [], remainingList: [], remaining: '', remainingLength: 0 };
    }
};


/**
* Truncates a list to X num of characters
*
* @param values {string[]}
* @param appendedChar {string}
* @param length {number}
*
*/
export function truncateArray(values: string[] = [], appendedChar = ', ', length = 32) {
    try {
        values = values ? values : [];
        let str = values.join(appendedChar);//values[0];
        // let allValues = values.join(appendedChar);
        let truncated = str.length > length ? str.substring(0, length) + '...' : str;
        let truncatedList = values;

        let lengthString = '';
        let count = 0;
        for (const str of values) {
            if ((lengthString + (lengthString ? ', ' : '') + str).length >= 32) {
                count++;
                break;
            }
            lengthString += (lengthString ? ', ' : '') + str;
            count++;
        }
        let remainingLength = values.length - count;
        let remaining = str.length > length;
        return {
            fullString: str,
            list: values,
            truncated: truncated,
            truncatedList: truncatedList,
            remaining: remaining,
            remainingLength: remainingLength == 0 ? '' : '+' + remainingLength
        };
    } catch (error) {
        return { list: [], truncated: '', truncatedList: [], remaining: '', remainingLength: 0 };
    }
};

export function formatDateToShowTimeZone(date: Date) {
    let month = date.getMonth() + 1;
    let dateOfMonth = date.getDate();
    let year = date.getFullYear();
    let timeFormat = date.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true });
    let timezone = date.toLocaleTimeString('en-us', { timeZoneName: 'short' }).split(' ')[2];

    return `${month + '/' + dateOfMonth + '/' + year + " " + timeFormat + " (" + timezone + ")"}`
}

/**
* Convert unix or epoch seconds to a Date object
* @param unix {number}
*
*/
export function convertUnixToDate(unix: number): Date {

    try {

        if (String(unix).length === 10) {
            return new Date(unix * 1000);
        } else {
            return new Date(unix);
        }

    } catch (error) {
        return new Date();
    }

}

/**
   * Truncates a string to X num of characters
   *
   * @param value {string}
   * @param length {number}
   *
   */
export function truncateString(value: string = '', length = 32) {
    try {
        let str = value ? value : '';
        let truncated = str.length > length ? str.substring(0, length) + '...' : str;
        let truncatedList = [truncated];
        let remainingLength = str.substring(length - 1, str.length).length;
        let remaining = str.length > length;
        return {
            list: [str],
            truncated: truncated,
            truncatedList: truncatedList,
            remaining: remaining,
            remainingLength: remainingLength == 0 ? '' : '(+' + remainingLength + ')'
        };
    } catch (error) {
        return { list: [], truncated: '', truncatedList: [], remaining: '', remainingLength: 0 };
    }
};


// export function getStatusIndex(status) {
//     switch (status) {
//         case 'NEW':
//             return 0;
//         case 'REVISED':
//             return 1;
//         case 'FULFILLED':
//             return 2;
//         default:
//             return 100;
//     }
// }

// export function getFeedOutStatusIndex(status) {
//     switch (status) {
//         case 'NEW':
//             return 0;
//         case 'REVISED':
//             return 1;
//         case 'FULFILLED':
//             return 2;
//         default:
//             return 100;
//     }
// }

